import Collapsible from 'react-collapsible';
import OpportunityTrigger from './opportunity_trigger';
import ReactTooltip from 'react-tooltip';
import VisibilityDecider from './visibilityDecider';
import {getOptions,getMessage} from './getOptions';
import NoIssuesFound from './noIssuesFound';

function CssBreakpoints(props){
   
   let items = props.report.offendersObj
   let options = getOptions(props,'ylb')
   let title = props.report.policy.label
   let msg = getMessage(options)
    msg = props.report.value

   let visibilityDecider = VisibilityDecider(props.config,options.is_critical,options.is_moderate,options.is_low,options.is_passed)

    return(

            <Collapsible lazyRender className={visibilityDecider} contentOuterClassName="panel" contentContainerTagName="div" triggerClassName="accordion" triggerOpenedClassName="accordion open" triggerTagName="button" trigger={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} msg={msg} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-down" title={title}/>]} triggerWhenOpen={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} msg={msg} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-up" title={title}/>]}>
                
            <p>This is the number of different breakpoints found in the stylesheets' media queries. Please note this rule is based on <i>min-width</i>, <i>max-width</i>, <i>min-device-width</i> and <i>max-device-width</i> media queries only. Your CSS will be easier to maintain if you keep a reasonable number of breakpoints. Try to make a fluid design - using percents - to avoid the creation of numerous breakpoints.</p>

            { Object.keys(items).length > 0 &&
            <table className="table table-striped table-responsive similar-color">
                <thead>
                <tr>
                    <th scope="col">Breakpoint</th>
                    <th scope="col">Count</th>   
                    <th scope="col">Pixels</th>              
                </tr>
                </thead>
                <tbody>

            {Object.keys(items).map((key, index) => {
              return (
                    
                        <tr key={index}>
                        <td>{items[index]['breakpoint']}</td>
                        <td>{items[index]['count']}</td>
                        <td>{items[index]['pixels']}</td>
                        </tr>
                       
              );
            })} 
                </tbody>
          </table>
          }
          { Object.keys(items).length === 0 &&
              <NoIssuesFound/>
          }           
            <ReactTooltip place="right" multiline={true} clickable={true} delayHide={1000} />
          </Collapsible>
          
    )
}

export default CssBreakpoints