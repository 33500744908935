import Collapsible from 'react-collapsible';
import ReactTooltip from 'react-tooltip';
import OpportunityTrigger from './opportunity_trigger';
import VisibilityDecider from './visibilityDecider';
import {getOptions,getMessage} from './getOptions';
import NoIssuesFound from './noIssuesFound';

function ThirdPartySummary(props){
   let items = props.report.details.items
   let save_seconds = (props.report.details.summary.wastedMs/1000).toFixed(2)
   let options = getOptions(props)
   let visibilityDecider = VisibilityDecider(props.config,options.is_critical,options.is_moderate,options.is_low,options.is_passed)

    return(

            <Collapsible lazyRender className={visibilityDecider} contentOuterClassName="panel" contentContainerTagName="div" triggerClassName="accordion" triggerOpenedClassName="accordion open" triggerTagName="button" trigger={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} save_seconds={save_seconds} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-down" title="Reduce the impact of third-party code"/>]} triggerWhenOpen={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} save_seconds={save_seconds} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-up" title="Reduce the impact of third-party code"/>]}>
                
            <p>Third-party code can significantly impact load performance. Limit the number of redundant third-party providers and try to load third-party code after your page has primarily finished loading. <a href="https://developers.google.com/web/fundamentals/performance/optimizing-content-efficiency/loading-third-party-javascript/" target="_blank">Learn more</a></p>
            { Object.keys(items).length > 0 &&
            <table className="table table-striped table-responsive">
                <thead>
                <tr>
                    <th scope="col">URL</th>
                    <th scope="col">Blocking Time</th>
                    <th scope="col">Main Thread Time</th>
                    <th scope="col">Size</th>                  
                </tr>
                </thead>
                <tbody>

            {Object.keys(items).map((key, index) => {
            if(items[index]['entity']['url'] !== undefined)
            {
                return (
                    <>
                    <tr className="parent-item" key={Math.random().toString(36).slice(2, 7)}>
                      <td><a data-tip={items[index]['entity']['url']} target="_blank" href={items[index]['entity']['url']}>{items[index]['entity']['url']}</a></td>
                      <td>{(items[index]['blockingTime']).toFixed(2)} ms</td>
                      <td>{(items[index]['mainThreadTime']).toFixed(2)} ms</td>
                      <td>{(items[index]['transferSize']/1000).toFixed(2)} kb</td>
                    </tr>
                    {Object.keys(items[index]['subItems']['items']).map((k, j) => {
                            return (
                                <>
                                <tr className="sub-item" key={Math.random().toString(36).slice(2, 7)}>
                                <td><a data-tip={items[index]['subItems']['items'][j]['url']} target="_blank" href={items[index]['subItems']['items'][j]['url']}>{items[index]['subItems']['items'][j]['url']}</a></td>
                                
                                <td>{ (isNaN(items[index]['subItems']['items'][j]['blockingTime'])) ? '' : (items[index]['subItems']['items'][j]['blockingTime']).toFixed(2)+ 'ms' }</td>
                                
                                <td>{(isNaN(items[index]['subItems']['items'][j]['mainThreadTime'])) ? '' : (items[index]['subItems']['items'][j]['mainThreadTime']).toFixed(2)+ 'ms' }</td>

                                <td>{(items[index]['subItems']['items'][j]['transferSize'])} kb</td>
                                </tr>
                                </>
                            );
                        })}
                    </>
                );
            }
              
            })} 
              </tbody>
          </table>
          }
          { Object.keys(items).length === 0 &&
              <NoIssuesFound/>
          }
          <ReactTooltip place="right" multiline={true} clickable={true} delayHide={1000} />
          </Collapsible>
          
    )
}

export default ThirdPartySummary