function VisibilityDecider(config,is_critical,is_moderate,is_low,is_passed){
    let visibilityDecider = 'show'
    switch (config['visibility-decider']) {
      case 'Critical':
        visibilityDecider = (is_critical) ? "show" : "hide"
        break;
     case 'Moderate':
        visibilityDecider = (is_moderate) ? "show" : "hide"
       break;
     case 'Low':
        visibilityDecider = (is_low) ? "show" : "hide"
       break;
     case 'Passed':
     visibilityDecider = (is_passed) ? "show" : "hide"
     break;
      default:
        visibilityDecider = 'show'
        break;
    }
    return visibilityDecider
}

export default VisibilityDecider