import "./css/sticky-cta.css"
function Footer(props){
    return(
        <div className="footer">
            <footer>
                <p>{props.redirectInfo.copyright}</p>
                { props.isShowCta &&

                    <div id="bottomsticky" className="footer-sticky">
                    <div id="bottomstickyfirst" className="footer-sticky-contact-img hidden-xs">
                        <img loading="lazy" className="img-responsive aligncenter" src={props.redirectInfo.cta_expert_img} width="120" height="154" alt="Nash" />
                    </div>
                    <div id="bottomstickysecond" className="footer-sticky-contact-text">
                        <p className="footer-sticky-title">{props.redirectInfo.cta_title}</p>
                        <p className="footer-sticky-description">{props.redirectInfo.cta_content}</p>
                    </div>
                    <div className="footer-sticky-contact-button">
                        <a className="btn" href={props.redirectInfo.cta_button_link}>{props.redirectInfo.cta_button_text}</a>
                    </div>
                    </div>
                }
            </footer>
        </div>
    )
}

export default Footer