function OverallExperience(props){

    let loading='inactive', origin='inactive',lighthouse='inactive', is_bad=false
    if(props.experience == 'loadingExperience')
    {
        loading='active'
        origin='active'
        lighthouse='active'
    }
    else if(props.experience == 'originLoadingExperience')
    {
        origin='active'
        lighthouse='active'
    }
    else
    {
        is_bad = true
        lighthouse='active'
    }

    return(
        <div className="over-infos experient">
            <h5 className="text-left"><img src="/img/indicate.png" alt="indicate"/> Showing Experiences</h5>
            <div className="over-infosin">
                <div className={`over-infos intab ${loading}`}>                    
                    { loading === 'active' &&
                        <>
                            <h5>Loading Experience&nbsp;<i style={{"color":"#3bbe13"}} className="fa fa-smile-o" aria-hidden="true"></i></h5>
                            <p>Specific URL has enough traffic and Chrome UX has the last 28 days real user experience records.</p>
                        </>
                    }
                    { loading === 'inactive' &&
                        <>
                            <h5 style={{"color":"red"}}>Loading Experience&nbsp;<i style={{"color":"red"}} className="fa fa-frown-o" aria-hidden="true"></i></h5>
                            <p>Chrome UX does not have the <b>last 28 days real user experience records</b>. Most probably <b>Your Web Page</b> having less traffic.</p>
                        </>
                    }                   
                </div>
                <div className={`over-infos intab ${origin}`}>
                    { origin === 'active' &&
                        <>
                            <h5>Origin Experience&nbsp;<i style={{"color":"#3bbe13"}} className="fa fa-smile-o" aria-hidden="true"></i></h5>
                            <p>Chrome UX have the overall site user experience for the last 28 days.</p>
                        </>
                    }
                    { origin === 'inactive' &&
                        <>
                            <h5 style={{"color":"red"}}>Origin Experience&nbsp;<i style={{"color":"red"}} className="fa fa-frown-o" aria-hidden="true"></i></h5>
                            <p>Chrome UX does not have the overall site <b>user experience for the last 28 days</b>. Most probably overall site having less traffic.</p>
                        </>
                    }
                </div>
                <div className={`over-infos intab ${lighthouse}`}>
                    <h5>Lighthouse</h5>
                    { is_bad == true &&
                        <p className="bad">Lab data only available! We are showing recommendations  based on the lab audit report.</p>
                    }
                    { is_bad == false &&
                        <p className="good">Lab data is available. We are showing the below recommendations based on the lab audit report.</p>
                    }
                </div>
            </div>
		</div>
    )
}

export default OverallExperience