import Collapsible from 'react-collapsible';
import OpportunityTrigger from './opportunity_trigger';
import ReactTooltip from 'react-tooltip';
import VisibilityDecider from './visibilityDecider';
import {getOptions,getMessage} from './getOptions';
import NoIssuesFound from './noIssuesFound';

function ScriptDuration(props){

   let options = getOptions(props,'ylb')
   let title = props.report.policy.label
   let msg = getMessage(options)

    msg = props.report.value + ' ms'

   let visibilityDecider = VisibilityDecider(props.config,options.is_critical,options.is_moderate,options.is_low,options.is_passed)

    return(

            <Collapsible lazyRender className={visibilityDecider} contentOuterClassName="panel" contentContainerTagName="div" triggerClassName="accordion" triggerOpenedClassName="accordion open" triggerTagName="button" trigger={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} msg={msg} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-down" title={title}/>]} triggerWhenOpen={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} msg={msg} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-up" title={title}/>]}>
                
            <p>This is the number of milliseconds spent by the browser on JavaScript execution during page load. For more details, try using the performance tab in Chrome DevTools. It is a bit complicated at first sight, but you'll be able to analyze exactly where this execution time is spent.</p>

            <table className="table table-striped table-responsive">
                <thead>
                <tr>
                    <th>{title}</th>
                    <th>User Experience</th>                  
                </tr>
                </thead>
                <tbody>
                    <tr>
                      <td>Less than or equal to {props.report.policy.isOkThreshold} ms</td>
                      <td>Good</td>
                    </tr>
                    <tr>
                      <td>Greater than {props.report.policy.isOkThreshold} ms and less than {props.report.policy.isBadThreshold} ms</td>
                      <td>Moderate</td>
                    </tr>
                    <tr>
                      <td>Greater than or equal to {props.report.policy.isAbnormalThreshold} ms</td>
                      <td>Bad</td>
                    </tr>
                </tbody>
            </table>
            { msg === 'Good' &&
              <NoIssuesFound/>
            }    
            <ReactTooltip place="right" multiline={true} clickable={true} delayHide={1000} />
          </Collapsible>
          
    )
}

export default ScriptDuration