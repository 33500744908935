import UrlDateCopy from "../common/urlDateCopy"
import SeoAnalytics from "./seoAnalytics"
import SeoInfo from "./seoInfo"
import SeoReport from "./seoReport"
import SeoScore from "./seoScore"
import "./css/seo.css"
import SeoReportGeneral from "./seoReportGeneral"
import Footer from '../common/footer';
import ContactForm from "../common/contactForm";

function SeoAudit(props){
    let auditDetails = props.auditDetails
    let blur = {filter: "blur(5px)"}
    if(!props.isShowRecommendations || props.isBulk)
      blur = {filter: "blur(0px)"} 
    return(
        <>
            <UrlDateCopy redirectInfo={props.redirectInfo} isReportFullyLoaded={props.isReportFullyLoaded} completedAudit={props.completedAudit} selectedAudit={props.selectedAudit} auditDetails={auditDetails} setCopied={props.setCopied} copied={props.copied} date={props.date} url={props.url} />
            <div className="seo">
                <SeoScore report={props.report.report}/>
                <div className="seo-summary">
                    <SeoInfo redirectInfo={props.redirectInfo} report={props.report.report}/>                    
                </div>       
            </div>
            {(props.isShowRecommendations && !props.isBulk) &&
            
            <ContactForm url={props.url} phone={props.phone} setPhone={props.setPhone} buttonText={props.buttonText} setButtonText={props.setButtonText} contactExpert={props.contactExpert} setContactExpert={props.setContactExpert} contactFormSuccessMessage={props.contactFormSuccessMessage} setContactFormSuccessMessage={props.setContactFormSuccessMessage} name={props.name} email={props.email} setName={props.setName} setEmail={props.setEmail} showModal={props.showModal} setShowModal={props.setShowModal} setIsShowRecommendations={props.setIsShowRecommendations}/>

            }
            <div style={blur}>
            <h3 className="analytics-title">Analytics Overview</h3>
            <div className="seo-analytics">
                <SeoAnalytics report={props.report.report}/>                  
            </div>
            <div className="seo-report over-infos lab opplab">
                <h5 className="text-left opp main-title">Recommendations To Improve Seo</h5>
                <SeoReport report={props.report.report}/>
                <h5 className="text-left opp main-title">General Recommendations</h5>
                <SeoReportGeneral report={props.report.report}/>
            </div>
            </div>
            {/* <Footer isShowCta={props.isShowCta} redirectInfo={props.redirectInfo} title="Looking for site speed optimization?" content="Book a free consultation with our Certified Experts" buttonText="Talk To Our Expert" expertImg="https://www.thecommerceshop.com/wp-content/uploads/2023/01/sticky-nash.png" /> */}
        </>
    )

}

export default SeoAudit