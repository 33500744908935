import Collapsible from 'react-collapsible';
import OpportunityTrigger from './opportunity_trigger';
import ReactTooltip from 'react-tooltip';
import VisibilityDecider from './visibilityDecider';
import {getOptions,getMessage} from './getOptions';
import NoIssuesFound from './noIssuesFound';

function IdenticalFiles(props){
   
   let items = props.report.offendersObj.list
   let options = getOptions(props,'ylb')
   let title = props.report.policy.label
   let msg = getMessage(options)

    msg = Object.keys(items).length

   let visibilityDecider = VisibilityDecider(props.config,options.is_critical,options.is_moderate,options.is_low,options.is_passed)


    return(

            <Collapsible lazyRender className={visibilityDecider} contentOuterClassName="panel" contentContainerTagName="div" triggerClassName="accordion" triggerOpenedClassName="accordion open" triggerTagName="button" trigger={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} msg={msg} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-down" title={title}/>]} triggerWhenOpen={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} msg={msg} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-up" title={title}/>]}>
                
            <p>This is the number of requests that could be avoided, because of downloaded files that have the same content but are loaded from different URLs.Try to load them from the same URL.</p>

            {Object.keys(items).map((key, index) => {
                let urls = items[index]['urls']
                return(
                <><ul>
                {Object.keys(urls).map((k, i) => {
                    return (                        
                            <li key={index+i}><a data-tip={urls[i]} target="_blank" href={urls[i]}>{urls[i]}</a></li>
                    );
                })} 
                </ul><hr/></>
                )            
            })}
            { Object.keys(items).length === 0 &&
                <NoIssuesFound/>
            }
            <ReactTooltip place="right" multiline={true} clickable={true} delayHide={1000} />
          </Collapsible>
          
    )
}

export default IdenticalFiles