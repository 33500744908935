import { Outlet, Link } from "react-router-dom";
import "./App.css";


const Layout = () => {
  return (
    <>
      {/* <div className="header">
        <p className="text-center"><a href="/"><img src="/img/the-commerce-shop.png"/></a></p>
      </div> */}
      <Outlet />
    </>
  )
};

export default Layout;