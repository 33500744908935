import Collapsible from 'react-collapsible';
import ReactTooltip from 'react-tooltip';
import NoIssuesFound from './noIssuesFound';
import OpportunityTrigger from './opportunity_trigger';
import VisibilityDecider from './visibilityDecider';
import {getOptions,getMessage} from './getOptions';


function OffscreenImages(props){
   
  let items = props.report.details.items
  let save_seconds = props.report.numericValue/1000
  let options = getOptions(props)
  let visibilityDecider = VisibilityDecider(props.config,options.is_critical,options.is_moderate,options.is_low,options.is_passed)

    return(

            <Collapsible lazyRender className={visibilityDecider} contentOuterClassName="panel" contentContainerTagName="div" triggerClassName="accordion" triggerOpenedClassName="accordion open" triggerTagName="button" trigger={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} save_seconds={save_seconds} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-down" title="Defer offscreen images"/>]} triggerWhenOpen={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} save_seconds={save_seconds} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-up" title="Defer offscreen images"/>]}>
                
            <p>Consider lazy-loading offscreen and hidden images after all critical resources have finished loading to lower time to interactive. <a href="https://web.dev/offscreen-images/" target="_blank">Learn more</a></p>
            
            { Object.keys(items).length > 0 &&
              <table className="table table-striped table-responsive">
                  <thead>
                  <tr>
                      <th scope="col">URL</th>
                      <th scope="col">Size</th>                   
                  </tr>
                  </thead>
                  <tbody>
                    {Object.keys(items).map((key, index) => {
                      return (
                                <tr key={key}>
                                  <td><a data-tip={items[index]['url']} target="_blank" href={items[index]['url']}>{items[index]['url']}</a></td>
                                  <td>{Math.round(items[index]['totalBytes']/1000)} KB</td>
                                </tr>
                      );
                    })} 
                </tbody>
              </table>
          }
          { Object.keys(items).length === 0 &&
              <NoIssuesFound/>
          }
          <ReactTooltip place="right" multiline={true} clickable={true} delayHide={1000} />
          </Collapsible>
          
    )
}

export default OffscreenImages