import Collapsible from 'react-collapsible';
import OpportunityTrigger from './opportunity_trigger';
import ReactTooltip from 'react-tooltip';
import VisibilityDecider from './visibilityDecider';
import {getOptions,getMessage} from './getOptions';
import NoIssuesFound from './noIssuesFound';

function SimilarColors(props){
   
   let items = props.report.offendersObj.list
   let options = getOptions(props,'ylb')
   let title = props.report.policy.label
   let msg = getMessage(options)

    msg = props.report.value

   let visibilityDecider = VisibilityDecider(props.config,options.is_critical,options.is_moderate,options.is_low,options.is_passed)

    return(

            <Collapsible lazyRender className={visibilityDecider} contentOuterClassName="panel" contentContainerTagName="div" triggerClassName="accordion" triggerOpenedClassName="accordion open" triggerTagName="button" trigger={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} msg={msg} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-down" title={title}/>]} triggerWhenOpen={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} msg={msg} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-up" title={title}/>]}>
                
            <p>This is the list of colors found in the stylesheets, that are very close to each other. The eye can barely see the difference. Use this list to reduce the number of colors in your palette, it will be easier to maintain.</p>

            { Object.keys(items).length > 0 &&
            <table className="table table-striped table-responsive similar-color">
                <thead>
                <tr>
                    <th scope="col">Color1</th>
                    <th scope="col">Color2</th>              
                </tr>
                </thead>
                <tbody>

            {Object.keys(items).map((key, index) => {
                let color1 = {
                    "width": "40px",
                    "height": "13px",
                    "background-color": items[index]['color1'],
                    "display": "inline-block",
                    "margin-left": "10px",
                    "border": "1px solid black"
                }
                let color2 = color1
                color2['background-color']=items[index]['color2']
                color2['margin-right']="10px"

              return (
                    
                        <tr key={index}>
                        <td>{items[index]['color1']}<span style={color1}></span></td>
                        <td><span style={color2}></span>{items[index]['color2']}</td>
                        </tr>
                       
              );
            })} 
                </tbody>
          </table>
          }
          { Object.keys(items).length === 0 &&
              <NoIssuesFound/>
          }           
            <ReactTooltip place="right" multiline={true} clickable={true} delayHide={1000} />
          </Collapsible>
          
    )
}

export default SimilarColors