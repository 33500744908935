import {useState,useEffect} from 'react';
import 'react-tabs/style/react-tabs.css';
import AuditTabs from './report/components/cwv/tab';
import "react-step-progress-bar/styles.css";
import getData from './report/components/api/get_data';
import { useLocation } from 'react-router-dom';
import parse from "url-parse";
import './report/components/style/style.css';
import { ColorRing } from 'react-loader-spinner';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Favicon from 'react-favicon';

const Report = () => {

  // Get report ID from the current URL
  const location = useLocation()
  const parsed = parse(location.search, true)
  const report_id = parsed.pathname.split("/").pop()

  // Declare state variables
  var [noReports,setNoReports] = useState(false)
  var [reports,setReports] = useState({
                                mobile:{psi:{},ylb:{}},
                                desktop:{psi:{},ylb:{}},
                                seranking:{},
                                security:{}
                              })
  var [config,setConfig] = useState({
    "visibility-decider":"All"
  })
  var [copied, setCopied] = useState('COPY REPORT')
  var [loaderTxt, setLoaderTxt] = useState('we are auditing your page ')
  var [auditDetails, setAuditDetails] = useState([])
  var [selectedAudit, setSelectedAudit] = useState([])
  var [completedAudit, setCompletedAudit] = useState([])
  var [notCompletedAudit, setNotCompletedAudit] = useState([])
  var [auditDate, setAuditDate] = useState('')
  var [auditUrl,setAuditUrl] = useState('')
  const [renderCount,setRenderCount] = useState(0)
  const [percentage,setPercentage] = useState(0)
  const [isReportFullyLoaded,setIsReportFullyLoaded] = useState(false)
  const [mainTabIndex, setMainTabIndex] = useState(88);
  const [subTabIndex, setSubTabIndex] = useState(0);
  const [redirectInfo, setRedirectInfo] = useState({
    favicon:'/favicon-default.png',
    title: 'Site Speed Test - Report Page'
  });

  const [isShowCta, SetIsShowCta] = useState(true);
  
  const [isShowRecommendations, setIsShowRecommendations] = useState(false);
  const [isBulk, setIsBulk] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [contactExpert, setContactExpert] = useState(1);
  const [contactFormSuccessMessage, setContactFormSuccessMessage] = useState({
    "msg":"",
    "success":false,
    "error":false,
    "loader":false,
  });
  const [buttonText, setButtonText] = useState('View Report');

  window.critical = 0

  // Trigger API - only once
  useEffect( () => {
        document.title = redirectInfo?.title;
        getData(report_id,setNoReports,setReports,completedAudit,setCompletedAudit,setLoaderTxt,auditDate,setAuditDate,auditUrl,setAuditUrl,setAuditDetails,toast,selectedAudit,setSelectedAudit,renderCount,setRenderCount,percentage,setPercentage,setIsReportFullyLoaded,setMainTabIndex,setSubTabIndex,setRedirectInfo,redirectInfo,setIsShowRecommendations,setIsBulk)
        
        //For sticky CTA
        // const onScroll = () => SetIsShowCta((window.pageYOffset > 100) ? true : false);
        // clean up code
        // window.removeEventListener('scroll', onScroll);
        // window.addEventListener('scroll', onScroll, { passive: true });
        // return () => window.removeEventListener('scroll', onScroll);
  }, [selectedAudit,renderCount])

  if(noReports)
    return (
      <div style={{"top":"0"}} className="spinner-text">
        <Favicon url={redirectInfo?.favicon} />
        <p>No reports found! Why don't you try a <a href="/">new test</a>?</p>
      </div>
    )
  if(isShowLoader(selectedAudit,completedAudit))
    return(
      <div className="spinner-section">
          <Favicon url={redirectInfo?.favicon} />
          <div className="spinner">
          <ColorRing
              visible={true}
              height="100"
              width="100"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
          />
          </div>
          <p className="spinner-text">Please wait, {loaderTxt}  Generally, it will take 1-2 minutes.</p>
      </div>
    )
  
  return (
    <>
      <Favicon url={redirectInfo?.favicon} />
      <AuditTabs isBulk={isBulk} phone={phone} setPhone={setPhone} buttonText={buttonText} setButtonText={setButtonText} contactExpert={contactExpert} setContactExpert={setContactExpert} contactFormSuccessMessage={contactFormSuccessMessage} setContactFormSuccessMessage={setContactFormSuccessMessage} name={name} email={email} setName={setName} setEmail={setEmail} showModal={showModal} setShowModal={setShowModal} isShowRecommendations={isShowRecommendations} setIsShowRecommendations={setIsShowRecommendations} isShowCta={isShowCta} setCopied={setCopied} copied={copied} config={config} setConfig={setConfig} report={reports} date={auditDate} auditUrl={auditUrl} completedAudit={completedAudit} auditDetails={auditDetails} selectedAudit={selectedAudit} setMainTabIndex={setMainTabIndex} mainTabIndex={mainTabIndex} subTabIndex={subTabIndex} isReportFullyLoaded={isReportFullyLoaded} setSubTabIndex={setSubTabIndex} redirectInfo={redirectInfo}/>
      <ToastContainer autoClose={5000}/>
    </>
  )
  
};

function isShowLoader(selectedAudit, completedAudit)
{
    if(selectedAudit.length === 6)
    {
      if(completedAudit.length === 0)
        return true
      if(completedAudit.includes('sec') || completedAudit.includes('ser'))
        return false
      if(completedAudit.includes('psi-mobile') || completedAudit.includes('psi-desktop'))
        return false
      return true
    }
    else
    {
      return false
    }
}

export default Report;