function TabsConfig(selectedAudit, completedAudit){

    let device = getDeviceBySelectedAudits(selectedAudit)

    let tabConfigurations = {
        performance: false,
        seo: false,
        security: false,
        mobile: false,
        desktop: false,
        device: device
    }

    
    if(device === 'mobile-desktop' || device === 'mobile' || device === 'desktop')
        tabConfigurations.performance = true
    if(device === 'mobile' || device === 'mobile-desktop')
        tabConfigurations.mobile = true
    if(device === 'desktop' || device === 'mobile-desktop')
        tabConfigurations.desktop = true
    if(selectedAudit.includes('ser'))
        tabConfigurations.seo = true
    if(selectedAudit.includes('sec'))
        tabConfigurations.security = true

    return tabConfigurations

}

function TabsLoaderConfig(selectedAudit,completedAudit,isReportFullyLoaded)
{
    let tabsLoaderConfigurations
    let device = getDeviceBySelectedAudits(selectedAudit)
    
    if(isReportFullyLoaded)
    {
        return tabsLoaderConfigurations = {
            performance: false,
            seo: false,
            security: false,
            mobile: false,
            desktop: false,
            device: device
        }

    }

    tabsLoaderConfigurations = {
        performance: true,
        seo: true,
        security: true,
        mobile: true,
        desktop: true,
        device: device
    }

    if(device === 'mobile-desktop')
    {
        tabsLoaderConfigurations.performance = (completedAudit.includes('psi-mobile') && completedAudit.includes('psi-desktop') && completedAudit.includes('ylb-mobile') && completedAudit.includes('ylb-desktop')) ? false : true
        tabsLoaderConfigurations.mobile = (completedAudit.includes('psi-mobile') && completedAudit.includes('ylb-mobile')) ? false : true
        tabsLoaderConfigurations.desktop = (completedAudit.includes('psi-desktop') && completedAudit.includes('ylb-desktop')) ? false : true
    }
    else if(device === 'mobile')
    {
        tabsLoaderConfigurations.mobile = (completedAudit.includes('psi-mobile') && completedAudit.includes('ylb-mobile')) ? false : true
        tabsLoaderConfigurations.performance = tabsLoaderConfigurations.mobile
    }   
    else if(device === 'desktop')
    {
        tabsLoaderConfigurations.desktop = (completedAudit.includes('psi-desktop') && completedAudit.includes('ylb-desktop')) ? false : true
        tabsLoaderConfigurations.performance = tabsLoaderConfigurations.desktop
    }
    
    tabsLoaderConfigurations.seo = (completedAudit.includes('ser')) ? false : true
    tabsLoaderConfigurations.security = (completedAudit.includes('sec')) ? false : true


    return tabsLoaderConfigurations

}

function TabsPanelConfig(selectedAudit, completedAudit){

    let device = getDeviceBySelectedAudits(selectedAudit)

    let TabsPanelConfiguration = {
        performance: false,
        seo: false,
        security: false,
        mobile: false,
        desktop: false,
        device: device
    }

    
    if(device === 'mobile-desktop' || device === 'mobile' || device === 'desktop')
        TabsPanelConfiguration.performance = true
    if(completedAudit.includes('psi-desktop'))
        TabsPanelConfiguration.desktop = true
    if(completedAudit.includes('psi-mobile'))
        TabsPanelConfiguration.mobile = true
    if(completedAudit.includes('ser'))
        TabsPanelConfiguration.seo = true
    if(completedAudit.includes('sec'))
        TabsPanelConfiguration.security = true

    return TabsPanelConfiguration

}

function getDeviceBySelectedAudits(selectedAudit)
{
    let device
    if(selectedAudit.length === 6 || selectedAudit.length === 4)
        device = 'mobile-desktop'
    else if(selectedAudit.length === 2)
        device = (selectedAudit.includes('psi-mobile')) ? 'mobile' : 'desktop'
    else
        device = 'seo-security'
    return device
}

function TabIndexes(mainTabIndex, setMainTabIndex, subTabIndex, setSubTabIndex,selectedAudit, completedAudit){

    if(mainTabIndex === 88 && selectedAudit.length !== 0)
    {
        if(selectedAudit.length === 6)
        {
            if(completedAudit.includes('psi-mobile'))
            {
                setMainTabIndex(0)
                setSubTabIndex(0)
            }
            else if(completedAudit.includes('psi-desktop'))
            {
                setMainTabIndex(0)
                setSubTabIndex(1)
            }
            else if(completedAudit.includes('ser'))
            {
                setMainTabIndex(1)
            }
            else if(completedAudit.includes('sec'))
            {
                setMainTabIndex(2)
            }
        }
        else{
            setMainTabIndex(0)
        
            if(selectedAudit.length === 2 && completedAudit.includes('psi-mobile') || completedAudit.includes('psi-desktop'))
                setSubTabIndex(0)
    
            if(selectedAudit.length === 4)
            {
                if(completedAudit.includes('psi-mobile'))
                {
                    setSubTabIndex(0)
                }
                else if(completedAudit.includes('psi-desktop'))
                {
                    setSubTabIndex(1)
                }
            }
        }
            
        
    }

}

export {TabsConfig,TabsLoaderConfig,TabsPanelConfig,TabIndexes}