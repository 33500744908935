import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Audit from './audit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobile, faDesktop, faQuestion, faL } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip';
import SeoAudit from '../seo/seoAudit';
import SecurityAudit from '../security/securityAudit';
import {TabsConfig, TabsLoaderConfig, TabsPanelConfig, TabIndexes} from '../common/tabConfig';
import Loader from '../common/loader';
import Header from '../common/header';
import Footer from '../common/footer';

function AuditTabs(props){

    let date = props.date
    let url = props.auditUrl
    let oldConfig, newConfig
    let handleVisibilityChangeOnSwitchTab = (index, setSubTabIndex) => {
        setSubTabIndex(index)
        oldConfig = props.config
        newConfig = {"visibility-decider":"All"}
        props.setConfig({...oldConfig,...newConfig})
    }

    let isReportFullyLoaded = props.isReportFullyLoaded

    let auditDetails = props.auditDetails
    let selectedAudit = props.selectedAudit
    let completedAudit = props.completedAudit
    
    let tab_configurations = TabsConfig(selectedAudit)
    let tab_loader_configurations = TabsLoaderConfig(selectedAudit,completedAudit,isReportFullyLoaded)
    let tab_panel_configurations = TabsPanelConfig(selectedAudit,completedAudit)

    let mainTabIndex = props.mainTabIndex
    let setMainTabIndex = props.setMainTabIndex
    let subTabIndex = props.subTabIndex
    let setSubTabIndex = props.setSubTabIndex
    TabIndexes(mainTabIndex,setMainTabIndex,subTabIndex,setSubTabIndex,selectedAudit,completedAudit)


    return (
        <>
          <Header redirectInfo={props.redirectInfo} src={props.redirectInfo.logo}/>
          <Tabs onSelect={(index) => props.setMainTabIndex(index)}>
            <TabList>
                { (tab_configurations.seo) ?
                <Tab disabled={tab_loader_configurations.seo}>
                  <img className="main-tab-icon black" src="/img/seo.png" />
                  <img className="main-tab-icon white" src="/img/seo-white.png" />
                  { tab_loader_configurations.seo ?
                    <>
                    <span className="main-tab-title disabled">SEO</span>
                    <img src="/img/loader-pulse.gif" width="30" height="30"/>
                    </> : <span className="main-tab-title">SEO</span>
                  }
                </Tab> : <></>
                }
                { (tab_configurations.performance) ?
                  <Tab>
                    <img className="main-tab-icon black" src="/img/performance.png" />
                    <img className="main-tab-icon white" src="/img/performance-white.png" />
                    { tab_loader_configurations.performance ?
                    <>
                      <span className="main-tab-title disabled">Site Performance</span>
                      <img src="/img/loader-pulse.gif" width="30" height="30"/>
                    </> : <span className="main-tab-title">Site Performance</span>
                    }
                  </Tab> : <></>
                }
                { (tab_configurations.security) ?
                <Tab disabled={tab_loader_configurations.security}>
                  <img className="main-tab-icon black" src="/img/security.png" />
                  <img className="main-tab-icon white" src="/img/security-white.png" />
                  { tab_loader_configurations.security ?
                    <>
                      <span className="main-tab-title disabled">Security</span>
                      <img src="/img/loader-pulse.gif" width="30" height="30"/>
                    </> : <span className="main-tab-title">Security</span>
                  }
                </Tab> : <></>
                }
            </TabList>
            { (tab_configurations.seo) ?
          <TabPanel>
            { (tab_panel_configurations.seo) ?
              <SeoAudit isBulk={props.isBulk} phone={props.phone} setPhone={props.setPhone} buttonText={props.buttonText} setButtonText={props.setButtonText} contactExpert={props.contactExpert} setContactExpert={props.setContactExpert} contactFormSuccessMessage={props.contactFormSuccessMessage} setContactFormSuccessMessage={props.setContactFormSuccessMessage} name={props.name} email={props.email} setName={props.setName} setEmail={props.setEmail} showModal={props.showModal} setShowModal={props.setShowModal} isShowRecommendations={props.isShowRecommendations} setIsShowRecommendations={props.setIsShowRecommendations} isShowCta={props.isShowCta} setCopied={props.setCopied} copied={props.copied} url={url} date={date} report={props?.report?.seranking} auditDetails={auditDetails} completedAudit={props.completedAudit} selectedAudit={props.selectedAudit} isReportFullyLoaded={props.isReportFullyLoaded} redirectInfo={props.redirectInfo}/> : <Loader content="Please wait, we are doing SEO audit for 200 pages from your website. Generally, it will take 5-10 minutes."/>
            }
            
          </TabPanel> : <></>
          }
            { (tab_configurations.performance) ?
            <TabPanel id="speed-test-tabs">
              <Tabs selectedIndex={subTabIndex} onSelect={(index) => handleVisibilityChangeOnSwitchTab(index,props.setSubTabIndex)}>
                <TabList id="speed"> 
                    { (tab_configurations.mobile) ?
                      <Tab>
                        <FontAwesomeIcon icon={faMobile} style={{"color":"blue"}}/>Mobile
                        { tab_loader_configurations.mobile &&
                          <img src="/img/loader-pulse.gif" width="30" height="30"/>
                        }
                      </Tab> : <></>
                    }
                    { (tab_configurations.desktop) ?
                    <Tab>
                      <FontAwesomeIcon icon={faDesktop} style={{"color":"blue"}}/>Desktop
                      { tab_loader_configurations.desktop &&
                        <img src="/img/loader-pulse.gif" width="30" height="30"/>
                      }
                    </Tab> : <></>
                    }
                </TabList>
                { (tab_configurations.mobile) ?
                  <TabPanel>
                    { (tab_panel_configurations.mobile) ?
                    <Audit isBulk={props.isBulk} phone={props.phone} setPhone={props.setPhone} buttonText={props.buttonText} setButtonText={props.setButtonText} contactExpert={props.contactExpert} setContactExpert={props.setContactExpert} contactFormSuccessMessage={props.contactFormSuccessMessage} setContactFormSuccessMessage={props.setContactFormSuccessMessage} name={props.name} email={props.email} setName={props.setName} setEmail={props.setEmail} showModal={props.showModal} setShowModal={props.setShowModal} isShowRecommendations={props.isShowRecommendations} setIsShowRecommendations={props.setIsShowRecommendations} isShowCta={props.isShowCta} setCopied={props.setCopied} copied={props.copied} url={url} date={date} config={props.config} setConfig={props.setConfig} device="mobile" data={props.report.mobile.psi} data-ylb={props.report.mobile.ylb} auditDetails={auditDetails} completedAudit={props.completedAudit} selectedAudit={props.selectedAudit} isReportFullyLoaded={props.isReportFullyLoaded} redirectInfo={props.redirectInfo}/> : <Loader content="Please wait we are auditing your web page in mobile. Generally, it will take 1-2 minutes."/> 
                    }            
                  </TabPanel> : <></>
                }
                { (tab_configurations.desktop) ?          
                <TabPanel>
                { (tab_panel_configurations.desktop) ?
                  <Audit isBulk={props.isBulk} phone={props.phone} setPhone={props.setPhone} buttonText={props.buttonText} setButtonText={props.setButtonText} contactExpert={props.contactExpert} setContactExpert={props.setContactExpert} contactFormSuccessMessage={props.contactFormSuccessMessage} setContactFormSuccessMessage={props.setContactFormSuccessMessage} name={props.name} email={props.email} setName={props.setName} setEmail={props.setEmail} showModal={props.showModal} setShowModal={props.setShowModal} isShowRecommendations={props.isShowRecommendations} setIsShowRecommendations={props.setIsShowRecommendations} isShowCta={props.isShowCta} setCopied={props.setCopied} copied={props.copied} url={url} date={date} config={props.config} setConfig={props.setConfig} device="desktop" data={props.report.desktop.psi} data-ylb={props.report.desktop.ylb} auditDetails={auditDetails} completedAudit={props.completedAudit} selectedAudit={props.selectedAudit} isReportFullyLoaded={props.isReportFullyLoaded} redirectInfo={props.redirectInfo}/> : <Loader content="Please wait we are auditing your web page in desktop. Generally, it will take 1-2 minutes."/>
                }
                
                </TabPanel> : <></>
                }
              </Tabs>
            </TabPanel> : <></>
            }
          
          { (tab_configurations.security) ?
          <TabPanel>
            { (tab_panel_configurations.security && Object.keys(props?.report?.security).length > 0) ?
            
            <SecurityAudit isBulk={props.isBulk} phone={props.phone} setPhone={props.setPhone} buttonText={props.buttonText} setButtonText={props.setButtonText} contactExpert={props.contactExpert} setContactExpert={props.setContactExpert} contactFormSuccessMessage={props.contactFormSuccessMessage} setContactFormSuccessMessage={props.setContactFormSuccessMessage} name={props.name} email={props.email} setName={props.setName} setEmail={props.setEmail} showModal={props.showModal} setShowModal={props.setShowModal} isShowRecommendations={props.isShowRecommendations} setIsShowRecommendations={props.setIsShowRecommendations} isShowCta={props.isShowCta} setCopied={props.setCopied} copied={props.copied} url={url} date={date} report={props?.report?.security} auditDetails={auditDetails} completedAudit={props.completedAudit} selectedAudit={props.selectedAudit} isReportFullyLoaded={props.isReportFullyLoaded} redirectInfo={props.redirectInfo}/> : <Loader content="Please wait we are doing security audit. Generally, it will take 1 minute."/>
            }
          </TabPanel> : <></>
          }
        </Tabs>
        {/* { (completedAudit.includes('psi-mobile') || completedAudit.includes('psi-desktop') || (completedAudit.includes('ser') || completedAudit.includes('sec'))) &&
          <Footer redirectInfo={props.redirectInfo} />
        } */}
        <ReactTooltip place="right" multiline={true} clickable={true} delayHide={1000} />
        </>
      );
}

export default AuditTabs