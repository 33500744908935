import ReactTooltip from 'react-tooltip';

function Status(props){
    let classNameColor = ( props.cwvStatus == 'Passed') ? 'success-txt' : 'crit'
    let is_passed = ( props.cwvStatus == 'Passed') ? true : false
    let is_no_data = ( props.cwvStatus == 'No Data') ? true : false
    return(
        <>
        <div className={`status-topin ${classNameColor}`}>
        { is_passed === true &&
            <><p className="score-topin topin">Core Web Vitals:&nbsp;<img src="/img/Icon awesome-question-circle.png" data-type="error" data-background-color="#3BBE13" data-tip="All three CWV params (Largest Contentful Paint (LCP),<br/> First Input Delay (FID), Cumulative Layout Shift (CLS))<br/> are having good user experience in the Chrome UX record." alt="help" currentitem="false"/></p>
            <span className={classNameColor}>{props.cwvStatus}</span></> 
        }
        { (is_passed === false && is_no_data === false) &&
        <><p className="score-topin topin">Core Web Vitals:&nbsp;<img src="/img/Icon awesome-question-circle.png" data-type="error" data-background-color="#F6491F" data-tip="Any one or more than one CWV params (Largest Contentful Paint (LCP),<br/> First Input Delay (FID), Cumulative Layout Shift (CLS))<br/> are not having good user experience in the Chrome UX record." alt="help" currentitem="false"/></p>
            <span className={classNameColor}>{props.cwvStatus}</span></>
        }
        { (is_no_data === true) &&
        <><p className="score-topin topin">Core Web Vitals:&nbsp;<img src="/img/Icon awesome-question-circle.png" data-type="error" data-background-color="#F6491F" data-tip="There is no enough sample user experience data available in the Chrome UX to calculate Core Web Vitals. Please check the below experience section to know more about available experiences for your website. It most probably, your site having less traffic. Please check with our expert to know more details." alt="help" currentitem="false"/></p>
            <span style={{"font":"normal normal bold 15px/25px Segoe UI"}}className={classNameColor}>Not enough customer field data is available in Chrome UX for last 28 days.</span></>
        }
        </div>
        
        <ReactTooltip place="right" multiline={true} clickable={true} delayHide={1000} />
        </>
    )
}

export default Status