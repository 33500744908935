import { Chart } from "react-google-charts";

function Experience(props){

    let data = [
        ["Experience","Value"],
        ["Good", props.data.good],
        ["Average", props.data.average],
        ["Bad", props.data.bad]
    ];

    let options = {
        chartArea: {
            height: '100%',
            width: '100%'
        },
        is3D: true,
        legend:{ position: "none" },
        colors: ['#3BBE13', '#F6A717', '#F6491F']
    };

    return(
        <>
            <Chart
                chartType="PieChart"
                data={data}
                options={options}
                width={"100%"}
                height={"auto"}
            />
        </>
    )
}

export default Experience