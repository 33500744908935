function OpportunityTrigger(props){
    
    let is_passed = (typeof props.is_passed === undefined ) ? false : props.is_passed
    let save = ''
    save = (props.save_seconds == undefined) ? props.save_bytes+' kb' : props.save_seconds+' s'
    let msg = ''
    msg = (props.msg == undefined) ? '' : props.msg

    if(msg !== '') save = msg
    
    return(
        <><div className="col-title"><h6><span className={props.classNameIndicators}>{save}</span> {props.title} </h6></div> 
            
            {props.is_critical ? (
            <div><span className="indi crit"><img src="/img/critical.png" />critical</span><i className={props.arrowDirectionClass} aria-hidden="true"></i></div>
            ):('')}

            {props.is_moderate ? (
            <div><span className="indi moderate"><img src="/img/moderate.png" />moderate</span><i className={props.arrowDirectionClass} aria-hidden="true"></i></div>
            ):('')}

            {props.is_low ? (
            <div><span className="indi low"><img src="/img/low.png" />low</span><i className={props.arrowDirectionClass} aria-hidden="true"></i></div>
            ):('')}

            {is_passed ? (
            <div><span className="indi passed"><img src="/img/passed.png" />passed</span><i className={props.arrowDirectionClass} aria-hidden="true"></i></div>
            ):('')} 
            
        </>
    )
}

export default OpportunityTrigger