// Import react-circular-progressbar module and styles
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
  } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CustomTextProgressbar from "./customTextProgressbar";
import ProgressProvider from "./progressProvider";

function SeoScore(props){
    let percentage = props.report.score_percent

    let txtColor = '#3BBE13'
    if(percentage < 50)
        txtColor = '#F6491F'
    else if(percentage < 90)
        txtColor = '#F6A717'

    return(
        <div className="health-score">

            <ProgressProvider valueStart={0} valueEnd={percentage}>
                
                {value => <CircularProgressbarWithChildren strokeWidth={10}
                styles={buildStyles({
                textColor: txtColor,
                textSize:"12px",
                pathColor: txtColor,
                trailColor: "#F7F7F7",
                rotation: 0.5 + (1 - percentage / 100) / 2
                })} value={66}>
                            <div className="seo-progressbar" style={{ marginTop: -5 }}>
                                <p>SEO SCORE<br/><span><b>{percentage}%</b></span></p>
                            </div>
                      </CircularProgressbarWithChildren>}
            </ProgressProvider>
        </div>
    )
}

export default SeoScore