function Header(props){
    return(
        <div class="header">
            <p class="text-center">
                <a href={props.redirectInfo.redirect_url}>
                    <img src={props.src}/>
                </a>
            </p>
        </div>
    )
}

export default Header