import Collapsible from 'react-collapsible';
import NoIssuesFound from './noIssuesFound';
import ReactTooltip from 'react-tooltip';
import OpportunityTrigger from './opportunity_trigger';
import VisibilityDecider from './visibilityDecider';
import {getOptions,getMessage} from './getOptions';

function PreloadLcpImage(props){
   let items = props.report.details.items
   let options = getOptions(props)
   let title = 'Preload largest contentful paint image'
   let msg = getMessage(options)

   let visibilityDecider = VisibilityDecider(props.config,options.is_critical,options.is_moderate,options.is_low,options.is_passed)

    return(

            <Collapsible lazyRender className={visibilityDecider} contentOuterClassName="panel" contentContainerTagName="div" triggerClassName="accordion" triggerOpenedClassName="accordion open" triggerTagName="button" trigger={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} msg={msg} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-down" title={title}/>]} triggerWhenOpen={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} msg={msg} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-up" title={title}/>]}>
                
            <p>Preload the image used by the LCP element in order to improve your LCP time. <a href="https://web.dev/optimize-lcp/#preload-important-resources" target="_blank">Learn more</a></p>

            { Object.keys(items).length > 0 &&
              <table className="table table-striped table-responsive">
                  <thead>
                  <tr>
                      <th scope="col">URL</th>                  
                  </tr>
                  </thead>
                  <tbody>
                    {Object.keys(items).map((key, index) => {
                      return (
                                <tr key={key}>
                                  <td><a data-tip={items[index]['url']} target="_blank" href={items[index]['url']}>{items[index]['url']}</a></td>
                                </tr>
                      );
                    })} 
                </tbody>
              </table>
          }
          { Object.keys(items).length === 0 &&
              <NoIssuesFound/>
          }
          <ReactTooltip place="right" multiline={true} clickable={true} delayHide={1000} />
            
          </Collapsible>
          
    )
}

export default PreloadLcpImage