function getOptions(props,type = 'psi'){
    
    if(type == 'psi')
    {
        let score = props.report.score
        let is_critical = ( score < 0.5 ) ? true : false
        let is_moderate = ( score >= 0.5 && score <= 0.8  ) ? true : false
        let is_low = ( score > 0.8 && score < 1) ? true : false
        let is_passed = ( score == 1 ) ? true : false
        let classNameIndicators = ''
        if(is_critical) classNameIndicators += 'crit-score'
        if(is_low) classNameIndicators += 'low-score'
        if(is_moderate) classNameIndicators += 'moderate-score'
        if(is_passed) classNameIndicators += 'passed-score'
    
        return {
            is_critical: is_critical,
            is_moderate: is_moderate,
            is_low: is_low,
            is_passed: is_passed,
            classNameIndicators: classNameIndicators
        }
    }
    else
    {
        let score = props.report.score
        let is_critical = ( score < 75 ) ? true : false
        let is_moderate = ( score >= 75 && score <= 85  ) ? true : false
        let is_low = ( score > 85 && score < 100 ) ? true : false
        let is_passed = ( score == 100 ) ? true : false
        let classNameIndicators = ''
        if(is_critical) classNameIndicators += 'crit-score'
        if(is_low) classNameIndicators += 'low-score'
        if(is_moderate) classNameIndicators += 'moderate-score'
        if(is_passed) classNameIndicators += 'passed-score'
        return {
            is_critical: is_critical,
            is_moderate: is_moderate,
            is_low: is_low,
            is_passed: is_passed,
            classNameIndicators: classNameIndicators
        }
    }
    
}

function getMessage(options){
    let msg = 'Good'
    if(options.is_low)
        msg = 'Low'
    if(options.is_moderate)
        msg = 'Moderate'
    if(options.is_critical)
        msg = 'Critical'
    return msg
}

export {getOptions,getMessage}