import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import parse from "url-parse";
import { useLocation } from 'react-router-dom';
import axios from 'axios'

function ContactExpert(props){
    
    // Get report ID from the current URL
    const location = useLocation()
    const parsed = parse(location.search, true)
    const report_id = parsed.pathname.split("/").pop()

    let handleExpertSubmit = (e) => {
        e.preventDefault()
        let contactData = {
            "email":e.target.email.value,
            "name":e.target.name.value,
            "details":e.target.details.value,
            "report_id":e.target.report_id.value,
            "url":e.target.url.value
        }

      axios.post('https://r8sbh13wv3.execute-api.us-east-2.amazonaws.com/production/contact-expert', { contactData })
      .then(res=>{
        console.log(res);
        console.log(res.data);
      })
        console.log(contactData)
        
    }
    return(
        <Popup className="expert"
        trigger={<button className="button"> Talk To Our Expert </button>}
        modal
        nested
      >
        {close => (
          <div className="ex-modal">
            <button className="close" onClick={close}>
              &times;
            </button>
            <div className="header"> Talk To Our Expert </div>
            <div className="content">
                <form onSubmit={handleExpertSubmit}>
                    <input name="email" placeholder="Email" type="email"/>
                    <input name="name" placeholder="Full Name" type="name"/>
                    <input name="details" placeholder="Description" type="text"/>
                    <input hidden name="report_id" placeholder="Report ID" value={report_id} type="text"/>
                    <input hidden name="url" placeholder="URL" value={props.url} type="text"/>
                    <button type="submit">Submit</button>
                </form>
                <p className="success-txt hide">We will reach you soon!</p>
                <p className="error-txt hide">Something went wrong!</p>
            </div>
          </div>
        )}
      </Popup>
    )
}

export default ContactExpert