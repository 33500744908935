function SeoOpportunityTrigger(props){

    return(
        <>
            <div className="col-title">
                <h6>{props.title} </h6>
            </div> 
            <div>
                <span className="indi">
                    <img src="/img/critical.png" /><span>{props.error.error}</span>
                    <img src="/img/moderate.png" /><span>{props.error.warning}</span>
                    <img src="/img/low.png" /><span>{props.error.notice}</span>
                    <img src="/img/passed.png" /><span>{props.error.passed}</span>
                </span>
                <i className={props.arrowDirectionClass} aria-hidden="true"></i>
            </div>
        </>
    )
}

export default SeoOpportunityTrigger