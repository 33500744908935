import Collapsible from 'react-collapsible';
import OpportunityTrigger from './opportunity_trigger';
import ReactTooltip from 'react-tooltip';
import VisibilityDecider from './visibilityDecider';
import {getOptions,getMessage} from './getOptions';
import NoIssuesFound from './noIssuesFound';

function NotFound(props){
   
   let items = props.report.offendersObj.list
   let options = getOptions(props,'ylb')
   let title = props.report.policy.label
   let msg = getMessage(options)
    msg = props.report.value
      
   let visibilityDecider = VisibilityDecider(props.config,options.is_critical,options.is_moderate,options.is_low,options.is_passed)


    return(

            <Collapsible lazyRender className={visibilityDecider} contentOuterClassName="panel" contentContainerTagName="div" triggerClassName="accordion" triggerOpenedClassName="accordion open" triggerTagName="button" trigger={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} msg={msg} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-down" title={title}/>]} triggerWhenOpen={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} msg={msg} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-up" title={title}/>]}>
                
            <p>404 errors are never cached, so each time a page ask for it, it hits the server. Even if it is behind a CDN or a reverse-proxy cache.</p>

            { Object.keys(items).length > 0 &&
              <table className="table table-striped table-responsive">
                  <thead>
                  <tr>
                      <th scope="col">URL</th>               
                  </tr>
                  </thead>
                  <tbody>

              {Object.keys(items).map((key, index) => {
                return (
                          <tr key={index}>
                            <td><a data-tip={items[index]} target="_blank" href={items[index]}>{items[index]}</a></td>
                          </tr>
                );
              })} 
                  </tbody>
            </table>
          }
          { Object.keys(items).length === 0 &&
              <NoIssuesFound/>
          }           
          <ReactTooltip place="right" multiline={true} clickable={true} delayHide={1000} />
          </Collapsible>
          
    )
}

export default NotFound