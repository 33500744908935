import Collapsible from 'react-collapsible';
import OpportunityTrigger from './opportunity_trigger';
import ReactTooltip from 'react-tooltip';
import VisibilityDecider from './visibilityDecider';
import {getOptions,getMessage} from './getOptions';
import NoIssuesFound from './noIssuesFound';

function CssRedundantChildNodesSelectors(props){
   
   let items = props.report.offendersObj.byFile
   let options = getOptions(props,'ylb')
   let title = props.report.policy.label
   let msg = getMessage(options)
    msg = props.report.value

   let visibilityDecider = VisibilityDecider(props.config,options.is_critical,options.is_moderate,options.is_low,options.is_passed)

    return(

            <Collapsible lazyRender className={visibilityDecider} contentOuterClassName="panel" contentContainerTagName="div" triggerClassName="accordion" triggerOpenedClassName="accordion open" triggerTagName="button" trigger={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} msg={msg} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-down" title={title}/>]} triggerWhenOpen={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} msg={msg} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-up" title={title}/>]}>
                
            <p>Some tags included inside other tags are obvious. For example, when "ul li" is specified in a rule, "ul" can be removed because the "li" tag is nearly always inside an "ul" container (the "ol" container is quite rare). Same thing for "tr td", "select option", ...Lowering compexity in CSS selectors can make the page load a little faster.</p>

            <p><br/>Please find the sample 10 records. If you want full data, please <a href={props.redirectInfo.cta_button_link}>talk to our expert</a>.</p>

            { Object.keys(items).length > 0 &&
            <table className="table table-striped table-responsive">
                <thead>
                <tr>
                    <th scope="col">URL</th>
                    <th scope="col">Count</th>                  
                </tr>
                </thead>
                <tbody>

            {Object.keys(items).map((key, index) => {
              return (<>
                        <tr className="parent-item" key={index}>
                          <td>
                              { items[index]['url'] === '[inline CSS]' ? <span>{items[index]['url']}</span> : <a data-tip={items[index]['url']} target="_blank" href={items[index]['url']}>{items[index]['url']}</a>
                              }
                          </td>
                          <td>{items[index]['count']}</td>
                        </tr>
                        {Object.keys(items[index]['offenders']).map((k, j) => {
                            return (
                                <>
                                <tr className="sub-item" key={Math.random().toString(36).slice(2, 7)}>
                                <td><span><b>CSS:</b>{items[index]['offenders'][j]['css']}</span></td>
                                <td><span><b>Column:</b> {items[index]['offenders'][j]['column']}, <b>Line:</b> {items[index]['offenders'][j]['line']}</span></td>
                                </tr>
                                </>
                            );
                        })}
                    </>
              );
            })} 
                </tbody>
          </table>
          }
          { Object.keys(items).length === 0 &&
              <NoIssuesFound/>
          }           
            <ReactTooltip place="right" multiline={true} clickable={true} delayHide={1000} />
          </Collapsible>
          
    )
}

export default CssRedundantChildNodesSelectors