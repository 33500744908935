import Collapsible from 'react-collapsible';
import OpportunityTrigger from './opportunity_trigger';
import ReactTooltip from 'react-tooltip';
import VisibilityDecider from './visibilityDecider';
import {getOptions,getMessage} from './getOptions';
import NoIssuesFound from './noIssuesFound';
import DataTable from 'react-data-table-component';
import Table from '../table';

function DOMaccesses(props){
   
   let items = props.report.offendersObj.list.byType
   let options = getOptions(props,'ylb')
   let title = props.report.policy.label
   let msg = getMessage(options)
    msg = props.report.value
    let columns = [], rows = []

   let visibilityDecider = VisibilityDecider(props.config,options.is_critical,options.is_moderate,options.is_low,options.is_passed)

    return(

            <Collapsible lazyRender className={visibilityDecider} contentOuterClassName="panel" contentContainerTagName="div" triggerClassName="accordion" triggerOpenedClassName="accordion open" triggerTagName="button" trigger={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} msg={msg} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-down" title={title}/>]} triggerWhenOpen={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} msg={msg} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-up" title={title}/>]}>
                
            <p>This metric estimates the number of times the JavaScript reads, changes or binds the DOM.The more your JavaScript code accesses the DOM, the slower the page will load. Try, as much as possible, to have an HTML page fully generated by the server instead of making changes with JS. Try to reduce the number of queries by refactoring your JavaScript code. Binding too many events also has a cost.</p>

            <p><br/>Please find the sample 10 records in the each DOMAccess. If you want full data, please <a href={props.redirectInfo.cta_button_link}>talk to our expert</a>.</p>

            
            

            {Object.keys(items).map((key, index) => {

              return(
                <>
                {(items[key][0] !== undefined && items[key][0] !== null) ? 
                  (<>
                  <h5>{key}</h5>
                  <Table theadData={Object.keys(items[key][0])} tbodyData={items[key]}/>
                  <hr/>
                  </>)
                  : 
                  (<></>)
                }
                </>
              )

            })} 
                
          
          { Object.keys(items).length === 0 &&
              <NoIssuesFound/>
          }           
            <ReactTooltip place="right" multiline={true} clickable={true} delayHide={1000} />
          </Collapsible>
          
    )
}

export default DOMaccesses