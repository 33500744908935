import ReactTooltip from 'react-tooltip'

function Info(props){

    let scores = countScores(props)
    let performance = Math.round(props.scores.performance)
    let scoreClassName = ''
    if(props.scores.performance < 50)
        scoreClassName = 'score-crit'
    else if (props.scores.performance < 90)
        scoreClassName = 'score-moderate'
    else
        scoreClassName = 'score-passed'

    let lcp = props['audits']['largest-contentful-paint']['numericValue']
    let loadTimeClass = 'score-crit'
    if(lcp <= 5000 && props.device === 'mobile')
        loadTimeClass = 'score-passed'
    if(lcp <= 2500 && props.device === 'desktop')
        loadTimeClass = 'score-passed'
    
    return(
        <>
        <div className="status-topin">
            { (props.device === 'mobile') ?
                (<p className="score-topin"><span className={`score ${loadTimeClass}`}>{props['audits']['largest-contentful-paint']['displayValue']}</span><span className="score-desc">Loading Time&nbsp;<img src="/img/Icon awesome-question-circle.png"  data-tip="Here loading time means largest contentful paint.<br/> Largest Contentful Paint marks the time at which the largest text or image is painted.<br/> [Learn more](https://web.dev/lighthouse-largest-contentful-paint/).<br/> Network throttling(Slow 4G): 150 ms TCP RTT, 1,638.4 Kbps throughput (Simulated)" alt="help" data-place="bottom" currentitem="false"/></span></p>) :
                (
                <p className="score-topin"><span className={`score ${loadTimeClass}`}>{props['audits']['largest-contentful-paint']['displayValue']}</span><span className="score-desc">Loading Time&nbsp;<img src="/img/Icon awesome-question-circle.png"  data-tip="Here loading time means largest contentful paint.<br/> Largest Contentful Paint marks the time at which the largest text or image is painted.<br/> [Learn more](https://web.dev/lighthouse-largest-contentful-paint/).<br/> Network throttling: 40 ms TCP RTT, 10,240 Kbps throughput (Simulated)" alt="help" data-place="bottom" currentitem="false"/></span></p>
                )
            }
        </div>
        <div className="status-topin">
            { (props.device === 'mobile') ?
                (<p className="score-topin"><span className={`score ${scoreClassName}`}>{performance}/100</span><span className="score-desc">Score in slow 4G network&nbsp;<img src="/img/Icon awesome-question-circle.png"  data-tip="Network throttling(Slow 4G): 150 ms TCP RTT, 1,638.4 Kbps throughput (Simulated)" alt="help" data-place="bottom" currentitem="false"/></span></p>) :
                (
                <p className="score-topin"><span className={`score ${scoreClassName}`}>{performance}/100</span><span className="score-desc">Score&nbsp;<img src="/img/Icon awesome-question-circle.png"  data-tip="Network throttling: 40 ms TCP RTT, 10,240 Kbps throughput (Simulated)" alt="help" data-place="bottom" currentitem="false"/></span></p>
                )
            }
        </div>
        <div className="status-topin">
            <p className="score-topin"><span className="score">{scores.critical}</span><span className="score-desc">Critical Issues</span></p>
        </div>
        <div className="status-topin">
            <p className="score-topin orange"><span className="score">{scores.moderate}</span><span className="score-desc">Moderate Issues</span></p>
        </div>
        <div className="status-topin">
            <p className="score-topin purple"><span className="score">{scores.low}</span><span className="score-desc">Low Issues</span></p>
        </div>        
        <ReactTooltip place="right" multiline={true} clickable={true} delayHide={1000} />    
        </>
    )
}

function countScores(props)
{
   let critical = 0, moderate = 0, low = 0, score = undefined
   let items = props.audits
   let items_ylb = props['audits-ylb']
   let accepted_list = ['offscreen-images','uses-optimized-images','uses-responsive-images','modern-image-formats','lcp-lazy-loaded','unused-javascript','unminified-javascript','bootup-time','no-document-write','third-party-summary','unused-css-rules','unminified-css','font-display','server-response-time','uses-text-compression','uses-long-cache-ttl','total-byte-weight','render-blocking-resources','viewport','preload-lcp-image','dom-size','mainthread-work-breakdown','hiddenImages','jQueryVersion','jQueryVersionsLoaded','jsErrors','fontsCount','identicalFiles','emptyRequests','notFound','synchronousXHR','iframesCount','DOMaccesses','DOMidDuplicated','cssBreakpoints','cssColors','cssComplexSelectors','cssDuplicatedProperties','cssDuplicatedSelectors','cssEmptyRules','cssImportants','cssImports','cssMobileFirst','cssOldIEFixes','cssOldPropertyPrefixes','cssParsingErrors','cssRedundantBodySelectors','cssRedundantChildNodesSelectors','cssRules','eventsScrollBound','globalVariables','scriptDuration','similarColors']

   Object.keys(items).map((key, index) => {
       if(accepted_list.includes(key))
       {
            score = props['audits'][key]['score']
            critical = ( score < 0.5 ) ? critical+1 : critical
            moderate = ( score >= 0.5 && score <= 0.8  ) ? moderate+1 : moderate
            low = ( score > 0.8 && score < 1) ? low+1 : low
       }
   })
   if(props['audits-ylb'] !== undefined)
   {
        Object.keys(items_ylb).map((key, index) => {
            if(accepted_list.includes(key))
            {
                score = props['audits-ylb'][key]['score']
                critical = ( score < 75 ) ? critical+1 : critical
                moderate = ( score >= 75 && score <= 85  ) ? moderate+1 : moderate
                low = ( score > 85 && score < 100) ? low+1 : low
            }
        })
   }

   return {
       'critical':critical,
       'moderate':moderate,
       'low':low
   }
}

export default Info