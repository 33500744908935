function TalkToExpert(){
    return(
        <div className="expert">
            <div className="expert-left">
                <span>Below is the comprehensive audit result for your website. However, for an advanced report with personalized recommendations, talk to our experts today!</span>
            </div>
            <div className="expert-right">
                <a className="rvChatBotExLink expert-button">Talk To Our Expert</a>
            </div>
        </div>
    )
}

export default TalkToExpert