function SeoAnalytics(props){
    let total_pages = props.report.total_pages
    let index_bing = props.report.domain_props.index_bing
    let index_yahoo = props.report.domain_props.index_yahoo
    let index_google = props.report.domain_props.index_google
    let dt = props.report.domain_props.dt
    let backlinks = props.report.domain_props.backlinks
    let expdate = props.report.domain_props.expdate

    return(
        <>
            <div className="total-pages">
                <span>{total_pages}</span>
                <span>Total pages we audited</span>
            </div>
            <div className="backlinks">
                <span>{backlinks}</span>
                <span>Backlinks</span>
            </div>
            <div className="expdate">
                <span>{expdate}</span>
                <span>Domain expiry date</span>
            </div>
            <div className="dt">
                <span>{dt}</span>
                <span>Domain Trust</span>
            </div>
            <div className="index-google">
                    <span>{index_google}</span>
                    <span>Pages in Google</span>
            </div>
            <div className="index-yahoo">
                <span>{index_yahoo}</span>
                <span>Pages in Yahoo!</span>
            </div>
            <div className="index-bing">
                    <span>{index_bing}</span>
                    <span>Pages in Bing</span>
            </div>
        </>
    )
}

export default SeoAnalytics