const value_condition = {
    mobile: {
        fcp: [1800,3000],
        tti: [3800,7300],
        si: [3400,5800],
        tbt: [200,600],
        lcp: [2500,4000],
        cls: [0.1,0.25],
    },
    desktop: {
        fcp: [1800,3000],
        tti: [3800,7300],
        si: [3400,5800],
        tbt: [200,600],
        lcp: [2500,4000],
        cls: [0.1,0.25],
    }
}
function LabDataMetrics(props){

    let device = props.device
    let fcp_indicator = getIndicators(props['data']['first-contentful-paint']['numericValue'], value_condition[device]['fcp'][0],value_condition[device]['fcp'][1])
    let interactive_indicator = getIndicators(props['data']['interactive']['numericValue'], value_condition[device]['tti'][0],value_condition[device]['tti'][1])
    let speed_index_indicator = getIndicators(props['data']['speed-index']['numericValue'], value_condition[device]['si'][0],value_condition[device]['si'][1])
    let tbt_indicator = getIndicators(props['data']['total-blocking-time']['numericValue'], value_condition[device]['tbt'][0],value_condition[device]['tbt'][1])
    let lcp_indicator = getIndicators(props['data']['largest-contentful-paint']['numericValue'], value_condition[device]['lcp'][0],value_condition[device]['lcp'][1])
    let cls_indicator = getIndicators(props['data']['cumulative-layout-shift']['numericValue'], value_condition[device]['cls'][0],value_condition[device]['cls'][1])

    return(
        <div className="over-infos lab">
            <h5 className="text-left"><img src="/img/indicate.png" alt="indicate"/> Lab Data Metrics</h5>
            
            <ul className="list-inline d-flex w-100 result">
                <li className="w-50 text-left"><i className={ fcp_indicator.icon } aria-hidden="true"></i>
<span>First Contentful Paint</span> <span className={`ml-auto ${fcp_indicator.className}`}>{props['data']['first-contentful-paint']['displayValue']}</span></li>
                <li className="w-50 text-left"><i className={ interactive_indicator.icon } aria-hidden="true"></i>
<span>Time To Interactive</span><span className={`ml-auto ${interactive_indicator.className}`}>{props['data']['interactive']['displayValue']}</span></li>

            </ul>
            <ul className="list-inline d-flex w-100 result">
                <li className="w-50 text-left"><i className={ speed_index_indicator.icon } aria-hidden="true"></i>
<span>Speed Index</span><span className={`ml-auto ${speed_index_indicator.className}`}>{props['data']['speed-index']['displayValue']}</span></li>
                <li className="w-50 text-left"><i className={ tbt_indicator.icon } aria-hidden="true"></i>
<span>Total Blocking Time </span><span className={`ml-auto ${tbt_indicator.className}`}>{props['data']['total-blocking-time']['displayValue']}</span></li>
                
            </ul>
            <ul className="list-inline d-flex w-100 result">
                <li className="w-50 text-left"><i className={ lcp_indicator.icon } aria-hidden="true"></i>
<span>Largest Contentful Paint</span><span className={`ml-auto ${lcp_indicator.className}`}>{props['data']['largest-contentful-paint']['displayValue']}</span></li>
                <li className="w-50 text-left"><i className={ cls_indicator.icon } aria-hidden="true"></i>
<span>Cumulative Layout Shift</span><span className={`ml-auto ${cls_indicator.className}`}>{props['data']['cumulative-layout-shift']['displayValue']}</span></li>
                
            </ul>
		</div>
    )
}

function getIndicators(value,expectedValue,avgValue){
    let indicators = '', className = ''
    if(value > avgValue)
    {
        indicators = 'fa fa-exclamation-triangle'
        className = 'warning-txt'
    }
    else if(value > expectedValue)
    {
        indicators = 'fa fa-exclamation-triangle'
        className = 'warning-txt'
    }
    else
    {
        indicators = 'fa fa-circle'
        className = 'success-txt'
    }
    return {
            icon: indicators,
            className: className
    }
}

export default LabDataMetrics