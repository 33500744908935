import Collapsible from 'react-collapsible';
import ReactTooltip from 'react-tooltip';
import OpportunityTrigger from './opportunity_trigger';
import VisibilityDecider from './visibilityDecider';
import {getOptions,getMessage} from './getOptions';
import NoIssuesFound from './noIssuesFound';

function ReduceUnusedJs(props){
   let items = props.report.details.items
   let save_seconds = props.report.numericValue/1000
   let options = getOptions(props)
   let visibilityDecider = VisibilityDecider(props.config,options.is_critical,options.is_moderate,options.is_low,options.is_passed)

    return(

            <Collapsible lazyRender className={visibilityDecider} contentOuterClassName="panel" contentContainerTagName="div" triggerClassName="accordion" triggerOpenedClassName="accordion open" triggerTagName="button" trigger={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} save_seconds={save_seconds} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-down" title="Reduce unused javascript"/>]} triggerWhenOpen={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} save_seconds={save_seconds} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-up" title="Reduce unused javascript"/>]}>
                
            <p>Reduce unused JavaScript and defer loading scripts until they are required to decrease bytes consumed by network activity. <a href="https://web.dev/unused-javascript" target="_blank">Learn more</a></p>
            { Object.keys(items).length > 0 &&
            <table className="table table-striped table-responsive">
                <thead>
                <tr>
                    <th scope="col">URL</th>
                    <th scope="col">Transfer size</th>
                    <th scope="col">Unused Size</th>
                    <th scope="col">Unused Percent</th>                      
                </tr>
                </thead>
                <tbody>

            {Object.keys(items).map((key, index) => {
              return (
                        <tr key={index}>
                          <td><a data-tip={items[index]['url']} target="_blank" href={items[index]['url']}>{items[index]['url']}</a></td>
                          <td>{Math.round(items[index]['totalBytes']/1000)} KB</td>
                          <td>{Math.round(items[index]['wastedBytes']/1000)} KB</td>
                          <td>{Math.round(items[index]['wastedPercent'])}%</td>
                        </tr>
              );
            })} 
              </tbody>
          </table>
          }
          { Object.keys(items).length === 0 &&
              <NoIssuesFound/>
          }
          <ReactTooltip place="right" multiline={true} clickable={true} delayHide={1000} />
          </Collapsible>
          
    )
}

export default ReduceUnusedJs