import {useState,useEffect} from 'react'
import axios from 'axios'
import "./App.css";
import Bowser from "bowser";
import * as Validator from 'validatorjs';
import ReCAPTCHA from "react-google-recaptcha";
import React from 'react';
import { renderHook } from '@testing-library/react';

const browser = Bowser.getParser(window.navigator.userAgent);


function App() {
  const [url, setUrl] = useState("");
  const [redirectUuid, setRedirectUuid] = useState("");
  const [deviceTools, setDeviceTools] = useState("0");
  const [advancedReport, setAdvancedReport] = useState(0);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isHideEmail, setIsHideEmail] = useState("Hide");
  const [ip, setIP] = useState('');
  const [userAgent, setUserAgent] = useState('');
  const [emailRule, setEmailRule] = useState('');
  const [buttonName,setButtonName] = useState('Start Audit');
  const [buttonStatus,setButtonStatus] = useState('');

  const getData = async () => {
    const res = await axios.get('https://api.ipify.org?format=json')
    setIP(res.data.ip)
  }
  useEffect( () => {
    getData()
    setUserAgent(browser.getBrowserName()+', Version is '+browser.getBrowserVersion())
  }, [])

  let handleSubmit = async (e) => {

    e.preventDefault();
    setButtonName('Auditing In Progress...')
    setButtonStatus('disable')
    let device, tools
    //set device and tools based on the selected options
    switch(deviceTools)
    {
      case '0':
        device = 0;
        tools = 0;
        break;
      case '1':
        device = 0;
        tools = 1;
        break;
      case '2':
        device = 1;
        tools = 1;
        break;
      case '3':
        device = 2;
        tools = 1;
        break;
      case '4':
        device = 5;
        tools = 2;
        break;
      case '5':
        device = 5;
        tools = 3;
        break;
      default:
        break;        
    }

    // alert(emailRule)
    const data = {
      url: url,
      device:device,
      tools:tools,
      advanced_report: advancedReport ? 1 : 0,
      email: email,
      ip: ip,
      user_agent: userAgent,
      redirect_uuid: redirectUuid,
      country:'IN',
      region:'TN'
    }
    console.log(data)
    let rules = {
      url: 'required',
      advanced_report: 'required',
      email: emailRule,
      ip: 'required',
      user_agent: 'required'
    };

    let validation = new Validator(data, rules);

    let api_url = ''
    if(window.location.host === 'tools.audit.enterpriseapplicationdevelopers.com:3000')
      api_url = "https://r8sbh13wv3.execute-api.us-east-2.amazonaws.com/production/lead-add-url"
    else if(window.location.host === 'audit.thecommerceshop.com')
      api_url = "https://api.thecommerceshop.com/lead-add-url"
    else
      api_url = "https://r8sbh13wv3.execute-api.us-east-2.amazonaws.com/production/lead-add-url"
      
    if(validation.passes()){
      try {
        setMessage('')
        let res = await fetch(api_url, {
          method: "POST",
          redirect: "follow",
          body: JSON.stringify(data)
        });
        let resJson = await res.text();
        
        
        if (res.status === 200) {
          window.location.href = '/report/result/'+resJson
          setMessage("Processing....");
        } else {
          setMessage("Some error occured");
          setButtonName("Retry");
          setButtonStatus('');
        }
      } catch (err) {
        console.log(err);
      }
    }
    else{
      let msg = ''
      if(validation.errors.first('url'))
        msg = msg+','+validation.errors.first('url')
      if(validation.errors.first('email'))
        msg = msg+','+validation.errors.first('email')
      msg = msg.replace(/^,|,$/g, '');
      setMessage(msg)
      setButtonName("Start Audit");
      setButtonStatus('');
      // console.log(validation.errors.all())
    }
    
  };
  
  const handleAdvancedReport = (event) => {
    let value = event.target.checked;
    setAdvancedReport(value)
    if(!value)
    {
      setIsHideEmail('Hide');
      setEmailRule('')
    }
    else
    {
      setIsHideEmail('');
      setEmailRule('required|email')
    }
  }
  

  return (
    <>
    <div class="header">
            <p class="text-center">
                <img src="/img/the-commerce-shop.png"/>
            </p>
    </div>
    <div className="App">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={url}
          placeholder="URL"
          onChange={(e) => setUrl(e.target.value)}
        />
        
        <select onChange={(e) => setDeviceTools(e.target.value)}>
          <option value='0'>All(Site Speed Test, Seo, Security ) in Mobile and Desktop</option>
          <optgroup label="Site Speed Test">
            <option value='1'>Mobile & Desktop</option>
            <option value='2'>Mobile</option>
            <option value='3'>Desktop</option>
          </optgroup>
          <option value='4'>Seo</option>
          <option value='5'>Security</option>      
        </select>

        <input
          type="text"
          value={redirectUuid}
          placeholder="UUID"
          onChange={(e) => setRedirectUuid(e.target.value)}
        />
        
        <input 
          hidden
          type='checkbox' 
          onChange={handleAdvancedReport}
        />
        <span style={{"display":"none"}}>&nbsp;&nbsp;Do you want advanced report? <span style={{'color':'tomato'}}>We will send advanced report in email.</span></span>

        <input
          className={isHideEmail}
          type="text"
          value={email}
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        
        <div className="message">{message ? <p>{message}</p> : null}</div>
        
        <button className="start-audit" type="submit" disabled={buttonStatus}>{buttonName}</button>
      </form>
    </div>
    </>
  );
}

export default App;