import "./css/security.css"
function SecurityReport(props){
    let report = props.report
    return(
        <>
        <h3 className="header-audit-title">HTTP headers audit</h3>
        <table className="table table-striped table-responsive">
        <thead>
        <tr>
            <th scope="col">Test</th>
            <th scope="col">Pass</th> 
            <th scope="col">Score</th> 
            <th scope="col">Reason</th>              
        </tr>
        </thead>
        { Object.keys(report).map((key,index) => {
            return(
                    <tr key={report[key]['name']}>
                        <td>{key}</td>
                        { ( report[key]['pass'] === true ) ?
                            (<td><img src="/img/passed.png"/></td>) : (<td><img src="/img/critical.png"/></td>)
                        }
                        <td>{report[key]['score_modifier']}</td>
                        <td>{report[key]['score_description']}</td>
                    </tr>

                )
            })
        }
        </table><br/><br/>
        </>
    )
}

export default SecurityReport