import Collapsible from 'react-collapsible';
import NoIssuesFound from './noIssuesFound';
import OpportunityTrigger from './opportunity_trigger';
import VisibilityDecider from './visibilityDecider';
import {getOptions,getMessage} from './getOptions';

function Viewport(props){
   let score = props.report.score
   let options = getOptions(props)
   let title = props.report.title
   let msg = getMessage(options)

   let visibilityDecider = VisibilityDecider(props.config,options.is_critical,options.is_moderate,options.is_low,options.is_passed)

    return(

            <Collapsible lazyRender className={visibilityDecider} contentOuterClassName="panel" contentContainerTagName="div" triggerClassName="accordion" triggerOpenedClassName="accordion open" triggerTagName="button" trigger={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} msg={msg} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-down" title={title}/>]} triggerWhenOpen={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} msg={msg} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-up" title={title}/>]}>
                
            <p>A `&lt;meta name=&quot;viewport&quot;&gt;` not only optimizes your app for mobile screen sizes, but also prevents  <a href="https://developers.google.com/web/updates/2013/12/300ms-tap-delay-gone-away" target="_blank">a 300 millisecond delay to user input</a>. <a href="https://web.dev/viewport/" target="_blank">Learn more</a></p>

            { score === 1 &&
              <NoIssuesFound/>
            }
            
          </Collapsible>
          
    )
}

export default Viewport