import Collapsible from 'react-collapsible';
import SeoOpportunityTrigger from './seoOpportunityTrigger';

function SeoReportGeneral(props){
    let general_list = ['Website Security','Website Speed','JavaScript','CSS','Mobile Optimization','Performance']
    let report = props.report.sections
    let errors = getErrorsCount(report)
    return(
        <>
        { Object.keys(report).map((key,index) => {

            return(
                <>
                    { general_list.includes(report[index]['name']) === true &&
                        <>
                        
                        <Collapsible lazyRender contentOuterClassName="panel" contentContainerTagName="div" triggerClassName="accordion" triggerOpenedClassName="accordion open" triggerTagName="button" trigger={["", <SeoOpportunityTrigger error={errors[report[index]['uid']]} title={report[index]['name']} arrowDirectionClass="fa fa-angle-down"/>]} triggerWhenOpen={["", <SeoOpportunityTrigger error={errors[report[index]['uid']]} title={report[index]['name']} arrowDirectionClass="fa fa-angle-up"/>]}>
                            <p>
                                Please find the more details in the site performance and security test.
                            </p>
                            <table className="table table-striped table-responsive">
                        <thead>
                        <tr>
                            <th scope="col">Issues</th>
                            <th scope="col">Status</th>
                            <th scope="col">Affected Pages</th>                 
                        </tr>
                        </thead>
                        <tbody>
                        { Object.keys(report[index]['props']).map((k,j)=>{
                            return(
                                <tr key={report[index]['props'][k]['code']}>
                                    { report[index]['props'][k]['value'] === 0 &&
                                        <>
                                        <td>{report[index]['props'][k]['name']}</td>
                                        <td><img src="/img/passed.png"/></td>
                                        </>
                                    }
                                    { (report[index]['props'][k]['value'] !== 0 && report[index]['props'][k]['status'] === 'error') &&
                                        <>
                                        <td>{report[index]['props'][k]['name']}</td>
                                        <td><img src="/img/critical.png"/></td>
                                        </>
                                    }
                                    { (report[index]['props'][k]['value'] !== 0 && report[index]['props'][k]['status'] === 'warning') &&
                                        <>
                                        <td>{report[index]['props'][k]['name']}</td>
                                        <td><img src="/img/moderate.png"/></td>
                                        </>
                                    }
                                    { (report[index]['props'][k]['value'] !== 0 && report[index]['props'][k]['status'] === 'notice') &&
                                        <>
                                        <td>{report[index]['props'][k]['name']}</td>
                                        <td><img src="/img/low.png"/></td>
                                        </>
                                    }                                    
                                    <td>{report[index]['props'][k]['value']}</td>
                                </tr>
                            )
                        })
                        }
                        </tbody>
                        </table>
                        </Collapsible>
                    </>
                    }  
                </>                    
                )
            })
        }
        </>
    )
}

function getErrorsCount(report)
{
    let errors_count = 0
    let warning_count = 0
    let passed_count = 0
    let notice_count = 0
    let result = []
    let length = report.length;
    for(let i=0; i < length; i++)
    {
        for(let prop in report[i]['props'])
        {
            if( report[i]['props'][prop]['value'] === 0)
                passed_count += 1
            else if(report[i]['props'][prop]['status'] === 'error')
                errors_count += report[i]['props'][prop]['value']
            else if(report[i]['props'][prop]['status'] === 'notice')
                notice_count += report[i]['props'][prop]['value']
            else if(report[i]['props'][prop]['status'] === 'warning')
                warning_count += report[i]['props'][prop]['value'] 
        }
        result[report[i]['uid']] = []
        result[report[i]['uid']]['error'] = errors_count
        result[report[i]['uid']]['notice'] = notice_count
        result[report[i]['uid']]['warning'] = warning_count
        result[report[i]['uid']]['passed'] = passed_count
        errors_count = 0
        notice_count = 0
        warning_count = 0
        passed_count = 0
    }

    return result
}

export default SeoReportGeneral