import Experience from "./experience";
import ReactTooltip from 'react-tooltip';

function ExperienceWrapper(props){
    let is_show_button = true
    if (props['data']['lcp']['value'] == 'NA' && props['data']['fid']['value'] == 'NA' && props['data']['cls']['value'] == 'NA'){
        is_show_button = false
    }
    let lcp_border = '', fid_border = '', cls_border = ''
    if(props.data.lcp.smiley != 'fa fa-smile-o')
        lcp_border = 'border-red'
    if(props.data.fid.smiley != 'fa fa-smile-o')
        fid_border = 'border-red'
    if(props.data.cls.smiley != 'fa fa-smile-o')
        cls_border = 'border-red'

    return(
        <div className="over-infos experient">
            <h5 className="text-left my-4"><img src="/img/indicate.png" alt="indicate"/> Core Web Vitals & User Experience metrics</h5>
            <p className="text-left my-4">In the pie chart below, your website should have a 75% or more <span className="txt-green">Good User Experience</span> score. In case your User Experience score less than 75%, follow our personalized recommendations.</p>
            <div className="over-infosin exp">
                <div className="over-infos intab">
                    <h5>Largest Contentful Paint (LCP)<i className={props.data.lcp.smiley} aria-hidden="true"></i></h5>
                    <div className={`inner ${lcp_border}`}>
                        <h5 className="value">Avg {props['data']['lcp']['value']}&nbsp;<img src="/img/Icon awesome-question-circle.png" data-tip="It should be less than 2.5 seconds." alt="help" currentitem="false"/></h5>
                        { props['data']['lcp']['value'] != 'NA' &&
                            <Experience data={props.data.lcp}/>
                        }
                        { props['data']['lcp']['value'] == 'NA' &&
                            <p className="na-data">No LCP data is available in Chrome UX. Please generate traffic for this URL or Site.</p>
                        }
                    </div>
                </div>
                <div className="over-infos intab">
                    <h5>First Input Delay (FID)<i className={props.data.fid.smiley} aria-hidden="true"></i></h5>
                    <div className={`inner ${fid_border}`}>
                        <h5 className="value">Avg {props['data']['fid']['value']}&nbsp;<img src="/img/Icon awesome-question-circle.png" data-tip="It should be less than 100 milli seconds." alt="help" currentitem="false"/></h5>
                        { props['data']['fid']['value'] != 'NA' &&
                            <Experience data={props.data.fid}/>
                        }
                        { props['data']['fid']['value'] == 'NA' &&
                            <p className="na-data">No FID data is available in Chrome UX. Please generate traffic for this URL or Site.</p>
                        }
                    </div>
                </div>
                <div className="over-infos intab">
                    <h5>Cumulative Layout Shift (CLS)<i className={props.data.cls.smiley} aria-hidden="true"></i></h5>
                    <div className={`inner ${cls_border}`}>
                        <h5 className="value">Avg {props['data']['cls']['value']}&nbsp;<img src="/img/Icon awesome-question-circle.png" data-tip="It should be less than 0.1" alt="help" currentitem="false"/></h5>
                        { props['data']['cls']['value'] != 'NA' &&
                            <Experience data={props.data.cls}/>
                        }
                        { props['data']['cls']['value'] == 'NA' &&
                            <p className="na-data">No CLS data is available in Chrome UX. Please generate traffic for this URL or Site.</p>
                        }
                    </div>
                </div>
            </div>
            { is_show_button == true &&
                <div className="btn-area">
                    <div className="btn-top">
                        <div className="btn-area-in green">
                            <i className="fa fa-smile-o" aria-hidden="true"></i> Good
                        </div>
                        <div className="btn-area-in orange">
                            <i className="fa fa-meh-o" aria-hidden="true"></i> Average
                        </div>
                        <div className="btn-area-in red">
                            <i className="fa fa-frown-o" aria-hidden="true"></i> Bad
                        </div>
                    </div>
                </div>
            }
        <ReactTooltip place="right" multiline={true} clickable={true} delayHide={1000} />
	    </div>
    )
}

export default ExperienceWrapper