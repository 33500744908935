// Import react-circular-progressbar module and styles
import "react-circular-progressbar/dist/styles.css";

function SecurityInfo(props){
    let reports= props.report
    let info = getGradeAndInfo(reports)

    const gradeTextColor = {
        color: info.color,
    };

    return(
        <div className="security">
            <div className="grade">
                <span style={gradeTextColor}><b>{info.grade}</b></span>
                <span>Grade</span>
            </div>
            <div className="pass">
                <span><b>{info.pass}</b></span>
                <span>Passed</span>
            </div>
            <div className="fail">
                <span><b>{info.fail}</b></span>
                <span>Failed</span>
            </div>
            {/* <div className="sec-expert">
                <span>We only audited the HTTP headers in your website. However, to audit all the security issues from your website with personalized recommendations, talk to our experts today!</span>           
                <span><a className="rvChatBotExLink expert-button">Talk To Our Expert</a></span>
            </div>             */}
        </div>
    )
}

function getGradeAndInfo(reports){

    //Calculate score
    let score = 100
    let pass = 0, fail = 0
    for(let report in reports){
        score += reports[report]['score_modifier']
        if(reports[report]['pass'])
            pass += 1
        else
            fail += 1
    }

    //Get grade
    let grade = 'F'
    if(score >= 100)
        grade = 'A+'
    else if(score >= 90)
        grade = 'A'
    else if(score >= 85)
        grade = 'A-'
    else if(score >= 80)
        grade = 'B+'
    else if(score >= 70)
        grade = 'B'
    else if(score >= 65)
        grade = 'B-'
    else if(score >= 60)
        grade = 'C+'
    else if(score >= 50)
        grade = 'C'
    else if(score >= 45)
        grade = 'C-'
    else if(score >= 40)
        grade = 'D+'
    else if(score >= 30)
        grade = 'D'
    else if(score >= 40)
        grade = 'D+'
    else if(score >= 25)
        grade = 'D-'

    let color = '#f50707'
    if(grade === 'A+' || grade === 'A' || grade === 'A-')
        color = '#0c5406'
    else if(grade === 'B+' || grade === 'B' || grade === 'B-')
        color = '#c7c704'
    else if(grade === 'C+' || grade === 'C' || grade === 'C-')
        color = '#f58a07'
    else if(grade === 'D+' || grade === 'D' || grade === 'D-')
        color = '#f55a07'

    return {
        score: score,
        grade: grade,
        pass: pass,
        fail: fail,
        color:color
    }
}

export default SecurityInfo