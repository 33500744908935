import { ColorRing } from 'react-loader-spinner';

function Loader(props)
{
    return(
        <div class="loader-txt">
            <p>{props.content}</p>
            <img src="/img/loader-pulse.gif" width="50" height="50"/>
        </div>
      )
}

export default Loader