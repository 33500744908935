import Collapsible from 'react-collapsible';
import ReactTooltip from 'react-tooltip';
import OpportunityTrigger from './opportunity_trigger';
import VisibilityDecider from './visibilityDecider';
import {getOptions,getMessage} from './getOptions';

function DomSize(props){
   let items = props.report.details.items
   let msg = props.report.numericValue
   let options = getOptions(props)

   let visibilityDecider = VisibilityDecider(props.config,options.is_critical,options.is_moderate,options.is_low,options.is_passed)

    return(

            <Collapsible lazyRender className={visibilityDecider} contentOuterClassName="panel" contentContainerTagName="div" triggerClassName="accordion" triggerOpenedClassName="accordion open" triggerTagName="button" trigger={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} msg={msg} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-down" title="Avoid an excessive DOM size"/>]} triggerWhenOpen={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} msg={msg} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-up" title="Avoid an excessive DOM size"/>]}>
                
            <p>A large DOM will increase memory usage, cause longer  <a href="https://developers.google.com/web/fundamentals/performance/rendering/reduce-the-scope-and-complexity-of-style-calculations" target="_blank">style calculations</a>, and produce costly  <a href="https://developers.google.com/speed/articles/reflow" target="_blank">layout reflows</a>.  <a href="https://web.dev/unminified-css/" target="_blank">Learn more</a></p>

          </Collapsible>
          
    )
}

export default DomSize