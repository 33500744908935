import dateFormat, { masks } from "dateformat"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import CopyReportUrl from "./copyReportUrl"
import ReactTooltip from "react-tooltip"

function UrlDateCopy(props)
{
    let localDate = new Date(props.date)
    localDate = dateFormat(localDate, "mmmm dS yyyy h:MM:ss TT")
    let auditDetails = props.auditDetails
    return (
        <>
            <div className="info">
                <div className="info-url">
                    <span><b>Your Web Page:</b></span>
                    <span><a href={props.redirectInfo.redirect_url}><b><FontAwesomeIcon icon={faArrowLeft}/> New Test</b></a></span>
                    <span><a data-tip={props.url} data-type="info" data-place="top" data-offset={30} href={props.url} target="_blank">{props.url}</a></span>
                </div>          
                <div className="info-date">
                    <span><b>Date: </b></span>
                    <span>{localDate}</span>
                    <CopyReportUrl isReportFullyLoaded={props.isReportFullyLoaded} completedAudit={props.completedAudit} selectedAudit={props.selectedAudit} auditDetails={auditDetails} setCopied={props.setCopied} copied={props.copied} />
                </div> 
                <ReactTooltip place="right" multiline={true} clickable={true} />               
            </div>
        </>
    )
}

export default UrlDateCopy