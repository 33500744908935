import { Chart } from "react-google-charts";

function UnderstandPageWeight(props){
    let items = props.data.offendersObj.list.byType
    let data = [
        ["Type","Size"],
        ["Javascript", items.js.totalWeight/1000],
        ["CSS", items.css.totalWeight/1000],
        ["HTML", items.html.totalWeight/1000],
        ["JSON", items.json.totalWeight/1000],
        ["Image", items.image.totalWeight/1000],
        ["Other", items.other.totalWeight/1000],
        ["Video", items.video.totalWeight/1000],
        ["Web Font", items.webfont.totalWeight/1000],
    ];
    data = data.sort((a,b) => a[1] - b[1]);

    let items_r = props.data_r.offendersObj.list.byType
    let data_r = [
        ["Type","Size"],
        ["Javascript", items_r.js.length],
        ["CSS", items_r.css.length],
        ["HTML", items_r.html.length],
        ["JSON", items_r.json.length],
        ["Image", items_r.image.length],
        ["Other", items_r.other.length],
        ["Video", items_r.video.length],
        ["Web Font", items_r.webfont.length],
    ];
    data_r = data_r.sort((a,b) => a[1] - b[1]);
    
    let options = {
        chartArea: {
            height: '100%',
            width: '100%'
        },
        is3D: true,
        legend: { position: 'labeled'},
        colors: ['#1e5410','#31b80f','#b5fa05','#e5fa05','#fad905','#faa405','#fa7b05','#f50a0a']
    };

    return(
        <>
        <div className="over-infos lab">
            <h5 className="text-left"><img src="/img/indicate.png" alt="indicate"/> Understand - Page Weight & Page Requests</h5>
            <div className="over-infosin content-weight exp">
                <div className="over-infos intab">
                    <h5>By Weight</h5>
                    <div className="inner">
                        <h5 className="value">Total Weight: {(props.data.offendersObj.list.totalWeight/1000).toFixed(2)} KB</h5>
                        <Chart
                            chartType="PieChart"
                            data={data}
                            options={options}
                            width={"100%"}
                            height={"auto"}
                        />
                    </div>
                </div>
                <div className="over-infos intab">
                    <h5>By Requests</h5>
                    <div className="inner">
                        <h5 className="value">Total Requests: {(props.data_r.value)}</h5>
                        <Chart
                            chartType="PieChart"
                            data={data_r}
                            options={options}
                            width={"100%"}
                            height={"auto"}
                        />
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default UnderstandPageWeight