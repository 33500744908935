import Collapsible from 'react-collapsible';
import OpportunityTrigger from './opportunity_trigger';
import ReactTooltip from 'react-tooltip';
import VisibilityDecider from './visibilityDecider';
import {getOptions,getMessage} from './getOptions';
import NoIssuesFound from './noIssuesFound';

function CssColors(props){
   
   let items = props.report.offendersObj.palette
   let options = getOptions(props,'ylb')
   let title = props.report.policy.label
   let msg = getMessage(options)
    msg = props.report.value

   let visibilityDecider = VisibilityDecider(props.config,options.is_critical,options.is_moderate,options.is_low,options.is_passed)

    return(

            <Collapsible lazyRender className={visibilityDecider} contentOuterClassName="panel" contentContainerTagName="div" triggerClassName="accordion" triggerOpenedClassName="accordion open" triggerTagName="button" trigger={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} msg={msg} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-down" title={title}/>]} triggerWhenOpen={["", <OpportunityTrigger classNameIndicators={options.classNameIndicators} msg={msg} is_critical={options.is_critical} is_low={options.is_low} is_moderate={options.is_moderate} is_passed={options.is_passed} arrowDirectionClass="fa fa-angle-up" title={title}/>]}>
                
            <p>This is the number of different colors defined in CSS. Your CSS will be easier to maintain if you keep a small color set.</p>

            { Object.keys(items).length > 0 &&
            <table className="table table-striped table-responsive">
                <thead>
                <tr>
                    <th scope="col">Color</th>
                    <th scope="col">Occurrences</th>              
                </tr>
                </thead>
                <tbody>

            {Object.keys(items).map((key, index) => {
              return (
                        <tr key={index}>
                          <td>{items[index]['color']}</td>
                          <td>{items[index]['occurrences']}</td>
                        </tr>
              );
            })} 
                </tbody>
          </table>
          }
          { Object.keys(items).length === 0 &&
              <NoIssuesFound/>
          }           
            <ReactTooltip place="right" multiline={true} clickable={true} delayHide={1000} />
          </Collapsible>
          
    )
}

export default CssColors