import BootupTime from './bootup_time';
import DomSize from './dom_size';
import EmptyRequests from './emptyRequests';
import FontsCount from './fontsCount';
import FontDisplay from './font_display';
import HiddenImages from './hiddenImages';
import IdenticalFiles from './identicalFiles';
import IframesCount from './iframesCount';
import JQueryVersion from './jQueryVersion';
import JQueryVersionsLoaded from './jQueryVersionsLoaded';
import JsErrors from './jsErrors';
import LcpLazyLoaded from './lcp_lazy_loaded';
import MainthreadWorkBreakdown from './mainthread_work_breakdown';
import ModernImageFormats from './modern_image_formats';
import NoDocumentWrite from './no_document_write';
import OffscreenImages from './offscreen-images';
import PreloadLcpImage from './preload_lcp_image';
import ReduceUnusedCss from './reduce_unused_css';
import ReduceUnusedJs from './reduce_unused_js';
import RenderBlockingResources from './render_blocking_resources';
import ServerResponseTime from './server_response_time';
import ThirdPartySummary from './third_party_summary';
import TotalByteWeight from './total_byte_weight';
import UnminifiedCss from './unminified_css';
import UnminifiedJavascript from './unminified_javascript';
import UsesLongCacheTtl from './uses_long_cache_ttl';
import UsesOptimizedImages from './uses_optimized_images';
import UsesResponsiveImages from './uses_responsive_images';
import UsesTextCompression from './uses_text_compression';
import Viewport from './viewport';
import NotFound from './notFound';
import SynchronousXHR from './synchronousXHR';
import CssRules from './cssRules';
import CssColors from "./cssColors";
import CssComplexSelectors from './cssComplexSelectors';
import SimilarColors from './similarColors';
import CssBreakpoints from './cssBreakpoints';
import CssMobileFirst from './cssMobileFirst';
import CssParsingErrors from './cssParsingErrors';
import CssImports from './cssImports';
import CssDuplicatedSelectors from './cssDuplicatedSelectors';
import CssDuplicatedProperties from './cssDuplicatedProperties';
import CssEmptyRules from './cssEmptyRules';
import CssImportants from './cssImportants';
import CssOldIEFixes from './cssOldIEFixes';
import CssOldPropertyPrefixes from './cssOldPropertyPrefixes';
import CssRedundantBodySelectors from './cssRedundantBodySelectors';
import CssRedundantChildNodesSelectors from './cssRedundantChildNodesSelectors';
import GlobalVariables from './globalVariables';
import ScriptDuration from './scriptDuration';
import DOMidDuplicated from './DOMidDuplicated';
import DOMaccesses from './DOMaccesses';
import EventsScrollBound from './eventsScrollBound';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';


function OpportunityWrapper(props){
    let oldConfig, newConfig
    let handleVisibilityChange = (e) => {
        oldConfig = props.config
        newConfig = {"visibility-decider":e.target.value}
        props.setConfig({...oldConfig,...newConfig})
    }
    return(
        <div className="over-infos lab opplab">
            <div>
                <h5 className="text-left opp main-title">Recommendations To Improve Your Site Performance</h5>
                <div className="filter">
                <span><FontAwesomeIcon icon={faFilter}/></span>
                <select onChange={handleVisibilityChange}>
                    <option>All</option>
                    <option>Critical</option>
                    <option>Moderate</option>
                    <option>Low</option>
                    <option>Passed</option>
                </select>
                </div>
                
            </div>

            <h5>Images</h5>
            { props['audits']['offscreen-images'] != undefined &&
                <OffscreenImages setConfig={props.setConfig} config={props.config} report={props['audits']['offscreen-images']}/>
            }
            { props['audits']['uses-optimized-images'] != undefined &&
                <UsesOptimizedImages setConfig={props.setConfig} config={props.config} report={props['audits']['uses-optimized-images']}/>
            }
            { props['audits']['uses-responsive-images'] != undefined &&
                <UsesResponsiveImages setConfig={props.setConfig} config={props.config} report={props['audits']['uses-responsive-images']}/>
            }
            { props['audits']['modern-image-formats'] != undefined &&
                <ModernImageFormats setConfig={props.setConfig} config={props.config} report={props['audits']['modern-image-formats']}/>
            }
            { props['audits']['lcp-lazy-loaded'] != undefined &&
                <LcpLazyLoaded setConfig={props.setConfig} config={props.config} report={props['audits']['lcp-lazy-loaded']}/>
            }
            { props?.['audits-ylb']?.['hiddenImages'] != undefined &&
                <HiddenImages setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['hiddenImages']}/>
            }
            { props['audits']['preload-lcp-image'] != undefined &&
                <PreloadLcpImage setConfig={props.setConfig} config={props.config} report={props['audits']['preload-lcp-image']}/>
            }

            <h5>JavaScript</h5>
            { props['audits']['unused-javascript'] != undefined &&
                <ReduceUnusedJs setConfig={props.setConfig} config={props.config} report={props['audits']['unused-javascript']}/>
            }
            { props['audits']['unminified-javascript'] != undefined &&
                <UnminifiedJavascript setConfig={props.setConfig} config={props.config} report={props['audits']['unminified-javascript']}/>
            }
            { props['audits']['bootup-time'] != undefined &&
                <BootupTime setConfig={props.setConfig} config={props.config} report={props['audits']['bootup-time']}/>
            }
            { props['audits']['no-document-write'] != undefined &&
                <NoDocumentWrite setConfig={props.setConfig} config={props.config} report={props['audits']['no-document-write']}/>
            }
            { props['audits']['third-party-summary'] != undefined &&
                <ThirdPartySummary setConfig={props.setConfig} config={props.config} report={props['audits']['third-party-summary']}/>
            }
            { props?.['audits-ylb']?.['jQueryVersion'] != undefined &&
                <JQueryVersion setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['jQueryVersion']}/>
            }
            { props?.['audits-ylb']?.['jQueryVersionsLoaded'] != undefined &&        
                <JQueryVersionsLoaded setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['jQueryVersionsLoaded']}/>
            }
            { props?.['audits-ylb']?.['jsErrors'] != undefined &&
                <JsErrors setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['jsErrors']}/>
            }
            { props?.['audits-ylb']?.['globalVariables'] != undefined &&
                <GlobalVariables setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['globalVariables']}/>
            }
            { props?.['audits-ylb']?.['scriptDuration'] != undefined &&
                <ScriptDuration setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['scriptDuration']}/>
            }
            { props?.['audits-ylb']?.['DOMaccesses'] != undefined &&
                <DOMaccesses redirectInfo={props.redirectInfo} setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['DOMaccesses']}/>
            }
            { props?.['audits-ylb']?.['eventsScrollBound'] != undefined &&
                <EventsScrollBound setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['eventsScrollBound']}/>
            }

            <h5>CSS</h5>
            { props['audits']['unused-css-rules'] != undefined &&
                <ReduceUnusedCss setConfig={props.setConfig} config={props.config} report={props['audits']['unused-css-rules']}/>
            }
            { props['audits']['unminified-css'] != undefined &&
                <UnminifiedCss setConfig={props.setConfig} config={props.config} report={props['audits']['unminified-css']}/>
            }
            { props?.['audits-ylb']?.['cssRules'] != undefined &&
                <CssRules setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['cssRules']}/>
            }
            { props?.['audits-ylb']?.['cssComplexSelectors'] != undefined &&
                <CssComplexSelectors redirectInfo={props.redirectInfo} setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['cssComplexSelectors']}/>
            }
            { props?.['audits-ylb']?.['cssColors'] != undefined &&
                <CssColors setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['cssColors']}/>
            }
            { props?.['audits-ylb']?.['similarColors'] != undefined &&
                <SimilarColors setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['similarColors']}/>
            }
            { props?.['audits-ylb']?.['cssBreakpoints'] != undefined &&
                <CssBreakpoints setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['cssBreakpoints']}/>
            }
            { props?.['audits-ylb']?.['cssMobileFirst'] != undefined &&
                <CssMobileFirst setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['cssMobileFirst']}/>
            }
            { props?.['audits-ylb']?.['cssParsingErrors'] != undefined &&
                <CssParsingErrors setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['cssParsingErrors']}/>
            }
            { props?.['audits-ylb']?.['cssImports'] != undefined &&
                <CssImports setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['cssImports']}/>
            }
            { props?.['audits-ylb']?.['cssDuplicatedSelectors'] != undefined &&
                <CssDuplicatedSelectors setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['cssDuplicatedSelectors']}/>
            }
            { props?.['audits-ylb']?.['cssDuplicatedProperties'] != undefined &&
                <CssDuplicatedProperties setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['cssDuplicatedProperties']}/>
            }
            { props?.['audits-ylb']?.['cssEmptyRules'] != undefined &&
                <CssEmptyRules setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['cssEmptyRules']}/>
            }
            { props?.['audits-ylb']?.['cssImportants'] != undefined &&
                <CssImportants setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['cssImportants']}/>
            }
            { props?.['audits-ylb']?.['cssOldIEFixes'] != undefined &&
                <CssOldIEFixes setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['cssOldIEFixes']}/>
            }
            { props?.['audits-ylb']?.['cssOldPropertyPrefixes'] != undefined &&
                <CssOldPropertyPrefixes setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['cssOldPropertyPrefixes']}/>
            }
            { props?.['audits-ylb']?.['cssRedundantBodySelectors'] != undefined &&
                <CssRedundantBodySelectors setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['cssRedundantBodySelectors']}/>
            }
            { props?.['audits-ylb']?.['cssRedundantChildNodesSelectors'] != undefined &&
                <CssRedundantChildNodesSelectors redirectInfo={props.redirectInfo} setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['cssRedundantChildNodesSelectors']}/>
            }
            <h5>Fonts</h5>
            { props['audits']['font-display'] != undefined &&
                <FontDisplay setConfig={props.setConfig} config={props.config} report={props['audits']['font-display']}/>
            }            
            { props?.['audits-ylb']?.['fontsCount'] != undefined &&
                <FontsCount setConfig={props.setConfig} config={props.config} report={props?.['audits-ylb']?.['fontsCount']}/>
            }
            <h5>Server</h5>
            { props['audits']['server-response-time'] != undefined &&
                <ServerResponseTime setConfig={props.setConfig} config={props.config} report={props['audits']['server-response-time']}/>
            }
            { props['audits']['uses-text-compression'] != undefined &&
                <UsesTextCompression setConfig={props.setConfig} config={props.config} report={props['audits']['uses-text-compression']}/>
            }
            { props['audits']['uses-long-cache-ttl'] != undefined &&
                <UsesLongCacheTtl setConfig={props.setConfig} config={props.config} report={props['audits']['uses-long-cache-ttl']}/>
            }

            <h5>Requests</h5>
            { props['audits']['total-byte-weight'] != undefined &&
                <TotalByteWeight setConfig={props.setConfig} config={props.config} report={props['audits']['total-byte-weight']}/>
            }
            { props['audits']['render-blocking-resources'] != undefined &&
                <RenderBlockingResources setConfig={props.setConfig} config={props.config} report={props['audits']['render-blocking-resources']}/>
            }
            { props?.['audits-ylb']?.['identicalFiles'] != undefined &&
                <IdenticalFiles setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['identicalFiles']}/>
            }
            { props?.['audits-ylb']?.['emptyRequests'] != undefined &&
                <EmptyRequests setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['emptyRequests']}/>
            }
            { props?.['audits-ylb']?.['notFound'] != undefined &&
                <NotFound setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['notFound']}/>
            }
            { props?.['audits-ylb']?.['synchronousXHR'] != undefined &&
                <SynchronousXHR setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['synchronousXHR']}/>
            }
            
            <h5>Others</h5>
            { props['audits']['viewport'] != undefined &&
                <Viewport setConfig={props.setConfig} config={props.config} report={props['audits']['viewport']}/> 
            }
            { props['audits']['dom-size'] != undefined &&
                <DomSize setConfig={props.setConfig} config={props.config} report={props['audits']['dom-size']}/>  
            }
            { props['audits']['mainthread-work-breakdown'] != undefined &&          
                <MainthreadWorkBreakdown setConfig={props.setConfig} config={props.config} report={props['audits']['mainthread-work-breakdown']}/>
            }
            { props?.['audits-ylb']?.['iframesCount'] != undefined &&
                <IframesCount setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['iframesCount']}/>  
            }
            { props?.['audits-ylb']?.['DOMidDuplicated'] != undefined &&
                <DOMidDuplicated setConfig={props.setConfig} config={props.config} report = {props?.['audits-ylb']?.['DOMidDuplicated']}/>
            }  
        </div>
    )
}

export default OpportunityWrapper