import UrlDateCopy from "../common/urlDateCopy"
import SecurityInfo from "./info"
import SecurityReport from "./securityReport"
import Footer from '../common/footer';
import ContactForm from "../common/contactForm";

function SecurityAudit(props){
    let auditDetails = props.auditDetails
    let isSecurityReportAvailable = (Object.keys(props?.report?.report).length > 0) ? true : false
    let blur = {filter: "blur(5px)"}
    if(!props.isShowRecommendations || props.isBulk)
      blur = {filter: "blur(0px)"} 

    return(
        <>
            <UrlDateCopy redirectInfo={props.redirectInfo} isReportFullyLoaded={props.isReportFullyLoaded} completedAudit={props.completedAudit} selectedAudit={props.selectedAudit} auditDetails={auditDetails} setCopied={props.setCopied} copied={props.copied} date={props.date} url={props.url} />
            { (isSecurityReportAvailable) ?
                <>
                <SecurityInfo report={props.report.report}/>
                {(props.isShowRecommendations && !props.isBulk) &&
            
                    <ContactForm url={props.url} phone={props.phone} setPhone={props.setPhone} buttonText={props.buttonText} setButtonText={props.setButtonText} contactExpert={props.contactExpert} setContactExpert={props.setContactExpert} contactFormSuccessMessage={props.contactFormSuccessMessage} setContactFormSuccessMessage={props.setContactFormSuccessMessage} name={props.name} email={props.email} setName={props.setName} setEmail={props.setEmail} showModal={props.showModal} setShowModal={props.setShowModal} setIsShowRecommendations={props.setIsShowRecommendations}/>

                }
                <div style={blur}>
                    <SecurityReport report={props.report.report}/>
                </div>
                {/* <Footer isShowCta={props.isShowCta} redirectInfo={props.redirectInfo} title="Looking for site speed optimization?" content="Book a free consultation with our Certified Experts" buttonText="Talk To Our Expert" expertImg="https://www.thecommerceshop.com/wp-content/uploads/2023/01/sticky-nash.png" /> */}
                </> : <p className="security-error">Sorry something went wrong. Please run a new test again.</p>
                
            }            
        </>
    )

}

export default SecurityAudit