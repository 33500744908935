import {CopyToClipboard} from 'react-copy-to-clipboard'

function CopyReportUrl(props){
    
    let report_url = window.location.href
    let selected_audits = props.selectedAudit
    let completed_audit = props.completedAudit
    let content = ''
    if(!props.isReportFullyLoaded)
        content = '<p>Audit in progress. We are fetching the reports.</p>'
    else 
        content = getContent(selected_audits,completed_audit)

    let handleCopy = () => {
        props.setCopied('COPIED')
        setTimeout(function(){
            props.setCopied('COPY REPORT')
        },3000)
    }

    return(
            <>
                <span>
                    <b>Audit status</b> <img data-tip={content} data-html={true} data-type="info" data-place="right" data-offset={30} width="15" height="15" src="/img/Icon awesome-question-circle.png" alt="help"/>
                </span>
                <CopyToClipboard text={report_url} onCopy={handleCopy}>
                    <span><img src="/img/copy.png" /> <b>{props.copied}</b></span>
                </CopyToClipboard> 
            </>
    )

}

function getNotUpdatedReports(completed_reports, already_updated_reports){
    return completed_reports.filter(x => !already_updated_reports.includes(x)).concat(already_updated_reports.filter(x => !completed_reports.includes(x)));
}

function getContent(selected_audits,completed_audits)
{

    let content = ''
    if(completed_audits.length > 0)
    {
        content = '<h6>Audit status</h6><ul class="audit-details-completed">'
        for(let i = 0;i<completed_audits.length;i++)
        {
            switch (completed_audits[i]) {
                case 'sec':
                    content = content + '<li>Security test</li>'
                    break;
                case 'ser':
                    content = content + '<li>SEO test</li>'
                    break;
                case 'psi-mobile':
                content = content + '<li>Mobile performance test</li>'
                break;
                case 'psi-desktop':
                content = content + '<li>Desktop performance</li>'
                break;
                case 'ylb-mobile':
                content = content + '<li>Mobile best practices test</li>'
                break;
                case 'ylb-desktop':
                content = content + '<li>Desktop best practices test</li>'
                break;
            }
            
        }
        content = content + '</ul>'
    }
    let not_completed_audits = getNotUpdatedReports(selected_audits,completed_audits)
    if(not_completed_audits.length > 0)
    {
        content = content + '<ul class="audit-details-not-completed">'
        for(let i = 0;i<not_completed_audits.length;i++)
        {
            switch (not_completed_audits[i]) {
                case 'sec':
                    content = content + '<li>Security test</li>'
                    break;
                case 'ser':
                    content = content + '<li>SEO test</li>'
                    break;
                case 'psi-mobile':
                content = content + '<li>Mobile performance test</li>'
                break;
                case 'psi-desktop':
                content = content + '<li>Desktop performance</li>'
                break;
                case 'ylb-mobile':
                content = content + '<li>Mobile best practices test</li>'
                break;
                case 'ylb-desktop':
                content = content + '<li>Desktop best practices test</li>'
                break;
            }
        }
        content = content + '</ul>'
        content = content + '<span><img src="/img/info-i.png" style="margin-right:5px"/>Please retest if all test was not completed.</span>'
    }
    
    return  content
}


export default CopyReportUrl