import TalkToExpert from "../contact/talkToExpert"
import SeoTalkToExpert from "./seoTalkToExpert"

function SeoInfo(props){

    let errors = props.report.total_errors
    let warnings = props.report.total_warnings
    let notices = props.report.total_notices
    let passed = props.report.total_passed
    let total_pages = props.report.total_pages
    let index_bing = props.report.domain_props.index_bing
    let index_yahoo = props.report.domain_props.index_yahoo
    let index_google = props.report.domain_props.index_google
    let dt = props.report.domain_props.dt
    let backlinks = props.report.domain_props.backlinks
    let expdate = props.report.domain_props.expdate

    return(
        <>
            <div className="total-pages">
                <span>{total_pages}</span>
                <span>Pages Audited</span>
            </div>
            <div className="seo-passed">
                <span><b>{passed}</b></span>
                <span>Passed</span>
            </div>
            <div className="seo-error">
                <span><b>{errors}</b></span>
                <span>Errors</span>
            </div>
        
            <div className="seo-warning">
                <span><b>{warnings}</b></span>
                <span>Warning</span>
            </div>
            <div className="seo-notices">
                <span><b>{notices}</b></span>
                <span>Notices</span>
            </div>
            {/* <SeoTalkToExpert redirectInfo={props.redirectInfo}/> */}
        </>
    )

}

export default SeoInfo